﻿@import '../../../../brand-reference';
@import '../variables/reference';
@import '../mixins/reference';

$block: '#{$global-prefix}-snippet';

.#{$block} {
	&-enhanced {
		.ms-rtestate-write & {
			border: 1px solid #a6a6a6;
			box-sizing: border-box;
			margin-bottom: sp(3);
			min-height: 50px;
			padding: sp(5) sp(1) sp(1);
			position: relative;
			transition: border .3s ease;

			&::before {
				align-items: center;
				box-sizing: border-box;
				content: 'Snippet';
				display: flex;
				height: sp(4);
				left: 0;
				padding: 0 sp(1);
				position: absolute;
				top: 0;
				width: 100%;
			}

			&::after {
				background: transparent;
				content: '';
				cursor: pointer;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;
			}

			&--active,
			&:hover {
				border-color: $grey-dark;

				&::after {
					background: transparentize($white, .4);
				}
			}

			#ms-rterangecursor-start,
			#ms-rterangecursor-end {
				display: none;
			}

			&[data-name='AccordionGroup'] {
				&::before {
					content: 'Accordion Group';
				}
			}

			&[data-name='Tabs'] {
				&::before {
					content: 'Tabs';
				}
			}
		}
	}

	&-accordion,
	&-tabs {
		&__add,
		&__delete {
			display: inline-block;
			font-size: $font-size-smaller;
			text-transform: uppercase;
		}

		&__delete {
			$icon-add-size: 14px;
			bottom: sp(4);
			padding-left: calc(#{$icon-add-size} + #{sp(1)});
			position: absolute;

			&::before {
				@include background-image-fill('icons/delete', $accent-primary, $icon-add-size, $icon-add-size);
				content: '';
				height: $icon-add-size;
				left: 0;
				position: absolute;
				width: $icon-add-size;
			}
		}
	}
}
