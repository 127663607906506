﻿// reference/patterns/_tabs.scss

@import '../variables/reference';
@import '../mixins/reference';


@mixin tab-container {
	background-color: $tabs-background-color;
	border-left: 0;
	border-right: 0;
	overflow: auto;
	padding: sp(1) 0 0;
	position: relative;
	text-align: $tabs-text-align;
}

@mixin tab-list {
	@include clean-list;

	position: relative;

	&::after {
		@include content;
		background: $tabs-border-bottom-color;
		bottom: sp(1);
		height: $tabs-border-bottom-width;
		position: absolute;
		width: 100%;
	}
}

@mixin tab {
	display: inline-block;
	margin-bottom: sp(1) !important;
	margin-right: sp(2);

	@include media-min(sm) {
		margin-right: sp(3);
	}

	&:last-child {
		margin-right: 0;
	}
}

@mixin tab-link {
	border-bottom: $tabs-border-bottom-width solid transparent;
	color: $tabs-link-color;
	display: inline-block;
	font-family: $font-family-primary;
	font-size: $font-size-small;
	font-weight: $tabs-font-weight;
	line-height: 1;
	padding: 0 0 sp(1);
	text-decoration: none;
	text-transform: uppercase;

	@include media-min(sm) {
		color: $tabs-link-color;
		font-size: $font-size-medium;

		&:visited {
			color: $tabs-link-color;
		}

		&:hover {
			color: $tabs-link-color-hover;
			text-decoration: none;
		}
	}
}

@mixin tab-link--current {
	background-color: $tabs-selected-background-color;
	border-bottom-color: $tabs-selected-border-bottom-color;
	color: $tabs-selected-color;
	position: relative;
	z-index: 1;

	&,
	&:visited,
	&:hover {
		color: $tabs-selected-color;
	}
}

@mixin tab-content {
	display: none;
	padding: sp(1) 0;
	text-align: left;

	.ms-webpart-chrome-title {
		display: none; // Hide tab content title, given title is in a tab now
	}

	.ms-WPBorder,
	.ms-WPBorderBorderOnly {
		border: 0;
	}
}

@mixin tab-content--active {
	display: block;
}
