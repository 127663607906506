﻿@import '../../../../../brand-reference';

$block: '#{$global-prefix}-snippet-tabs';

.#{$block} {
	&-form-field {
		& + & {
			margin-top: sp(2);
		}
	}

	.#{$global-prefix}-label {
		font-size: $font-size-small;
	}

	.#{$global-prefix}-tab {
		&__link {
			cursor: move; // sass-lint:disable no-duplicate-properties
			cursor: grab; // sass-lint:disable no-duplicate-properties
		}

		&.gu-transit .#{$global-prefix}-tab__link {
			cursor: move; // sass-lint:disable no-duplicate-properties
			cursor: grabbing; // sass-lint:disable no-duplicate-properties
		}

		&__content {
			display: none;

			&--active {
				display: block;
			}
		}
	}

	&__add {
		$icon-add-size: 14px;
		padding-left: calc(#{$icon-add-size} + #{sp(1)});
		position: relative;

		&::before {
			@include background-image-stroke('tabs/add-tab', $accent-primary, $icon-add-size, $icon-add-size);
			content: '';
			height: $icon-add-size;
			left: 0;
			position: absolute;
			width: $icon-add-size;
		}
	}

	.gu-mirror {
		display: none;
	}
}

.ms-rtestate-write {
	.#{$global-prefix}-snippet-enhanced .#{$global-prefix}-tab {
		&__container {
			padding: 0 0 sp(10);
			position: relative;

			&::after {
				@include content;
				@include background-image('tabs/content-preview');
				height: sp(8);
				margin-top: sp(2);
				position: absolute;
				width: calc(100% - #{sp(6)});
			}
		}

		&__content {
			display: none;
		}

		&:first-child {
			&__link {
				@include tab-link--current;
			}
		}
	}
}
