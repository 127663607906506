﻿//style.css contains the custom CSS for fnha.com

//Authors: John Allan

@import 'mixins';

/*
 * TABLE OF CONTENTS
 * $BODY
 * $SHAREPOINTSTRUCTURE
 * $GLOBALSTYLES
 * $PANELS
 * $HEADER
 * * $TOPNAVIGATION
 * * $UTILITYNAVIGATION
 * $FOOTER
 * $WEBPARTCHROME
 * $UTILITY
 * $WIDTH < 48em
*/

/*#region $BODY */

body {
	font-size: 14px;
	overflow: hidden;

	&.fnha-anonymous {
		overflow: visible;
	}
}

/*#endregion*/


/*#region $SHAREPOINTSTRUCTURE */

#s4-workspace {
	/* touch capability for scrollable workspace div */
	-webkit-overflow-scrolling: touch;
}

.hpf-publishing #s4-bodyContainer {
	padding-bottom: 0;
}

.hpf-publishing #s4-titlerow {
	border-bottom: 1px solid transparent;
	padding-top: 22px;
	position: relative;
}

.hpf-publishing #titleAreaBox {
	display: block;
	margin: 0 auto;
	max-width: 1024px;
	position: relative;
}

.hpf-publishing #pageStatusBar[class],
.hpf-publishing .ms-status-msg {
	margin-bottom: 0;
}

.hpf-publishing .ms-core-sideNavBox-removeLeftMargin {
	margin: 0;
}

.hpf-publishing #contentRow,
.hpf-footer,
.hpf-ankle,
.hpf-page-container .hpf-page-title .hpf-page-title-inner {
	padding-top: 0;
}

.hpf-publishing #contentBox {
	margin: 0;
	min-width: 0;
}

.hpf-publishing #sideNavBox,
.hpf-publishing .hpf-zone-panel.hpf-aside-ontop {
	float: none;
	height: 0;
	left: 0;
	margin: 0;
	overflow: visible;
	position: absolute;
	top: 220px;
	width: 100%;
	z-index: 1;
}

.hpf-publishing.hpf-editMode .hpf-zone-panel.hpf-aside-ontop {
	position: static;
}

.hpf-publishing-landing #sideNavBox,
#sideNavBox.isHeroPage {
	top: 641px;
}

.hpf-publishing .hpf-zone-panel.hpf-aside-ontop {
	top: 587px;
}

.hpf-editMode #sideNavBox {
	display: none;
}

.hpf-publishing #sideNavBox .hpf-grid-group,
.hpf-publishing #sideNavBox .hpf-grid-container,
.hpf-publishing .hpf-zone-panel.hpf-aside-ontop .hpf-grid-row,
.hpf-publishing .hpf-zone-panel.hpf-aside-ontop .hpf-grid-container {
	height: 0;
	overflow: visible;
}

.hpf-page-container {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.hpf-page-container .hpf-zone-title {
	border-bottom: 9px solid transparent;
	border-top: 2px solid transparent;
	display: block;
	height: 170px;
}

.hpf-page-container .hpf-page-title .hpf-page-title-inner h1 {
	display: table-cell;
	height: 170px;
	vertical-align: middle;
}

.hpf-publishing-homepage .hpf-page-container .hpf-page-title,
.hpf-publishing-landing .hpf-page-container .hpf-page-title {
	display: none;
}

/*#endregion*/


/*#region $GLOBALSTYLES */

fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

.hpf-cleanList {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.hpf-list,
.ms-rtestate-field ul,
.hpf-arrowList,
.hpf-arrowList-large,
.hpf-arrowListOnDark,
.hpf-arrowListOnDark-alt2,
.hpf-arrowList-alt2,
.hpf-separatedInlineList-small {
	list-style: none;
	margin: 0 0 20px;
	padding: 0;
}

.hpf-list li,
.ms-rtestate-field li span,
.hpf-arrowList li,
.hpf-arrowList-large li,
.hpf-arrowListOnDark li,
.hpf-arrowListOnDark-alt2 li,
.hpf-arrowList-alt2 li,
.ms-rtestate-field ul li:not([class]) {
	background-repeat: no-repeat;
	display: block;
	list-style: none;
	margin: 0;
	padding: 3px 0 3px 15px;
}

.ms-rtestate-field ul {
	&.hpf-list-styled,
	&.hpf-list-styled li {
		list-style: disc;
		margin: 10px 0;
	}

	&.hpf-list-unstyled,
	&.hpf-list-unstyled li {
		list-style: none;
		margin: 10px 0;
	}
}

.hpf-separatedInlineList-small li {
	@include display-inline-block;
	background-repeat: no-repeat;
	font-size: 11px;
	margin-left: 3px;
	padding-left: 9px;
}

.hpf-separatedInlineList-small li:first-child {
	margin-left: 0;
	padding-left: 0;
}

.hpf-button,
.hpf-button-alt1,
.hpf-button-small,
.hpf-button-big,
.hpf-button-big-alt1,
.hpf-button-share,
.ms-rteElement-button,
.ms-rteElement-button-download {
	@include display-inline-block;
	border: 1px solid transparent;
	font-size: 14px;
	padding: 9px 13px;
}

.hpf-button-small {
	font-size: 13px;
	padding: 1px 10px;
}

.hpf-button-share {
	float: left;
	margin-right: 10px;
	padding-left: 40px;
}

.hpf-button-download,
.ms-rteElement-button-download {
	font-size: 11px;
	padding: 3px 10px 3px 20px;
}

.hpf-button-big,
.hpf-button-big-alt1 {
	font-size: 16px;
	margin-right: 5px;
	min-width: 150px;
	padding: 12px 20px 10px;
	text-align: center;
}

.hpf-button-big + .hpf-button-big,
.hpf-button-big-alt1 + .hpf-button-big-alt1 {
	margin-left: 5px;
}


/*#endregion*/


/*#region $PANELS */

.hpf-zone-panel {
	border-top: 1px solid transparent;
}

.hpf-zone-panel .hpf-grid-row {
	padding: 35px 0 25px;
}

.hpf-hasZoneTitle {
	.hpf-zoneTitle {
		margin-bottom: -20px !important;
		padding-bottom: 0;

		.ms-webpartzone-cell {
			margin-bottom: 0;
		}

		.ms-WPBody {
			display: none;
		}
	}
}

.hpf-zone-title {
	border-top-width: 2px;
}

.hpf-zone-hero .hpf-grid-row,
.hpf-zone-title .hpf-grid-row {
	padding: 0;
}

.hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-grid-row,
.hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-grid-row {
	min-height: 300px;
}

/*#endregion*/


/*#region $HEADER*/

.hpf-topNavigation-launcher,
.hpf-headerSearch-launcher {
	display: none;
}

#s4-titlerow {
	height: 152px;
}

// Hide logo image
.ms-siteicon-img {
	display: none !important;
}

.ms-siteicon-a {
	$logo-width: 270px;
	$logo-height: 138px;

	@include image-replacement;

	background-image: url('#{$brand-images}/header/fnha-brand-page-logo.png');
	background-repeat: no-repeat;
	background-size: $logo-width $logo-height;
	height: $logo-height;
	margin-right: 62px;
	max-height: none;
	max-width: $logo-width;
	width: $logo-width;

	img {
		// Hide the out-of-the-box SharePoint site icon
		display: none;
	}
}

.ms-core-pageTitle {
	display: none;
}

@media #{$media-screen-md} {
	.ms-siteicon-a {
		background-image: url('#{$brand-images}/header/fnha-brand-page-logo-mobile.png');
		background-repeat: no-repeat;
		background-size: 50px 32px;
		display: block;
		height: 32px;
		left: 10px;
		position: absolute;
		top: 19px;
		width: 50px;

		a {
			display: block;
			height: 32px;
		}
	}
}

/*#region $TOPNAVIGATION*/

#DeltaTopNavigation {
	display: block;
	padding-top: 87px;
}

#DeltaTopNavigation li.static {
	margin-right: 32px;
	padding-bottom: 20px;
}

#DeltaTopNavigation .hpf-navigationSubtitle {
	display: block;
	font-size: 12px;
	margin-top: 1px;
	padding-left: 1px;
}

/* top nav flyout anchor text */
#DeltaTopNavigation ul.dynamic {
	border-top: 1px solid transparent;
	padding: 0;
	width: auto !important;
}

#DeltaTopNavigation a.dynamic {
	border-top: 1px solid transparent;
	padding: 10px 18px;
	white-space: nowrap;
}

/*#endregion*/


/*#region $UTILITYNAVIGATION*/

.hcf-HeaderNav {
	float: left;
}

ul.hpf-utilityNav {
	float: left;
	margin: 9px 0 0;
}

ul.hpf-utilityNav li {
	float: left;
	line-height: 14px;
	margin-right: 8px;
}

ul.hpf-utilityNav.hpf-cleanList li {
	margin-right: 8px;
}

ul.hpf-utilityNav li a {
	font-size: 11px;
	margin-right: 12px;
	text-transform: uppercase;
}

ul.hpf-utilityNav a.hpf-headerSocialIcon {
	background-color: #fff;
	background-repeat: no-repeat;
	height: 16px;
	margin-right: 0;
	width: 16px;
}

ul.hpf-utilityNav li a.hpf-facebook {
	background-position: -50px -200px;
}

ul.hpf-utilityNav li a.hpf-twitter {
	background-position: -75px -200px;
}

ul.hpf-utilityNav li a.hpf-linkedin {
	background-position: -100px -200px;
}

ul.hpf-utilityNav li a.hpf-youtube {
	background-position: -125px -200px;
}

ul.hpf-utilityNav li a.hpf-instagram {
	background-position: -150px -200px;
}

#searchInputBox {
	margin-left: 12px;
}

.ms-mpSearchBox {
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

/*#endregion*/


/*#region $LEFTNAV*/

.hpf-publishing #sideNavBox .Menu-Vertical a {
	color: #718674;
}

.hpf-publishing #sideNavBox .Menu-Vertical li a {
	border-left: 6px solid transparent;
	display: block;
	padding: 15px 20px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li {
	border-left: 6px solid transparent;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li ul {
	padding-bottom: 10px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li a {
	border-left: 0;
	border-top: 1px solid transparent;
	font-size: 15px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li.selected.hasChildren a {
	padding-bottom: 5px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li {
	border: 0;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li a {
	border: 0;
	padding: 5px 20px 5px 34px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li ul {
	padding-bottom: 0;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li li a {
	padding: 3px 20px 3px 46px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li li li a {
	padding: 3px 20px 3px 58px;
}

/*#endregion*/


/*#region $BREADCRUMB*/

#hpf-breadcrumb {
	border-top: 1px solid transparent;

	span span {
		@include display-inline-block;
		padding: 12px 7px 12px 0;
	}

	img {
		display: none;
	}
}

/*#endregion*/


/*#region $ANKLE*/

.hpf-ankle {
	padding: 30px 0 45px;
	text-align: center;
}

.hpf-ankle h2 {
	font-size: 24px;
	font-style: italic;
	margin-bottom: 12px;
}

.hpf-ankle label {
	display: block;
	margin-bottom: 5px;
}

.hpf-ankle input {
	@include box-sizing(border-box);
	margin-right: 5px;
	padding: 9px 7px;
	vertical-align: top;
	width: 255px;
}

.hpf-subscribe label {
	@include visually-hidden;
}

.hpf-subscribeMessage {
	display: none;
	text-align: center;
}

/*#endregion*/


/*#region $TWITTER WEBPART*/

.hpf-twitterLongAgo {
	font-size: 11px;
	margin: 0 0 5px;
	text-transform: uppercase;
}

/*#endregion*/


/*#region $LARGEROLLUP */

.hpf-largeRollup {
	margin-bottom: 15px;

	> li {
		margin: 40px 0;
		position: relative;

		&:first-child {
			margin-top: 0;
		}
	}

	li.hpf-listPaging {
		margin: 25px 0 -25px;
	}

	li.hpf-featureNewsItem {
		float: left;
		margin: 0;
		width: 43%;

		img {
			@include box-sizing(border-box);
			margin-bottom: 1.5em;
			max-width: 100%;
		}

		p {
			margin: .5em 0;
		}
	}

	li.hpf-nonFeatureNewsItem {
		margin-left: 63%;
		margin-top: 25px;
	}

	li.hpf-eventRollupItem {
		padding-left: 42px;

		&.hpf-eventResult {
			padding-left: 0;
		}
	}

	.hpf-eventCalendar {
		height: 45px;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 31px;
	}
}

/*#endregion*/

/*#region $REGULARROLLUP */

.hpf-regularRollup {
	margin-bottom: 30px;

	> li {
		margin: 50px 0;
		position: relative;
	}

	> li.hpf-listPaging {
		margin: 25px 0 -25px;
	}

	img {
		position: static;
	}

	> li:first-child {
		margin-top: 0;
	}
}

/*#endregion*/


/*#region $ASIDEROLLUP */

.hpf-aside {
	z-index: 2;

	.ms-webpartzone-cell {
		margin-bottom: 50px;
	}
}

/*#endregion*/


/*#region $ASIDEROLLUP */

.hpf-asideRollup {
	margin-bottom: 50px;
	position: relative;
}

/*#endregion*/


/*#region $FEATUREDNEWS*/

.hpf-seeMore-featuredNews {
	margin-left: 63%;
}

/*#endregion*/


/*#region $RELATEDLINKS*/

.hpf-asideFixedWebpart {
	//display: none;
}

.hpf-editMode .hpf-asideFixedWebpart {
	display: block;
}


.hpf-relatedLinks h2 {
	margin-bottom: 5px;
}

.hpf-relatedLinks .dfwp-column,
.hpf-relatedLinks .item {
	margin: 0;
	padding: 0;
}

/*#endregion*/


/*#region $FEEDBACKFORM*/

.hpf-feedbackForm {
	.hpf-grid-group {
		padding: 0 0 10px;
	}

	label {
		display: block;
		margin-bottom: 5px;
	}

	input,
	textarea,
	select {
		@include border-radius(3px);
		@include box-sizing(border-box);
		padding: 6px 10px;
		width: 100%;
	}

	textarea {
		height: 135px;
	}

	p.hpf-feedbackWarning,
	p.hpf-feebackMessage {
		display: none;
	}

	.hpf-button {
		float: right;
		margin-left: 20px;
	}
}

/*#endregion*/

/*#region $INTAKEFORM*/

.hpf-intake {
	color: #444;
	display: grid;
	grid-column-gap: 10%;
	grid-row-gap: 15px;
	grid-template-columns: 1fr 1fr;
	padding-top: 10px;
	width: 100%;

	&-text-body {
		font-size: 14px;
		font-weight: 400;
	}

	&-text-body--em {
		text-decoration: underline;
	}

	&-text-body--light {
		font-size: 14px;
		font-weight: 300;
	}

	&-text-body--bold {
		font-size: 14px;
		font-weight: 700;
	}

	&-text-heading {
		font-size: 18px;
		font-weight: 700;
	}

	section {
		margin-block-start: 40px;

		&:first-of-type {
			margin-block-start: 30px;
		}
	}

	.hpf-intake-radio-wrapper {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-block-start: 10px;
	}

	input[type="date"] {
		&::placeholder {
			color: #c3bfb9;
		}
	}

	textarea {
		height: 135px;
	}

	input[type="text"],
	input[type="date"],
	select,
	textarea {
		background-clip: padding-box;
		border: 1px solid #b6b1a9;
		border-bottom-color: #c3bfb9;
		border-radius: 3px;
		box-sizing: border-box;
		color: #292c39;
		margin-block-start: 5px;
		padding: 6px 10px;
		width: 100%;

		&:hover {
			border-color: #92c0e0;
		}

		&:focus {
			color: #292c39;
		}
	}

	.hpf-submit-button-wrapper {
		display: flex;
		justify-content: flex-end;
	}

	input[type='radio'] {
		margin-right: 6px;
		margin-top: -1px;
	}

	.hpf-left-col {
		grid-column: 1 / 2;
	}

	.hpf-right-col {
		grid-column: 2 / 3;
	}

	.hpf-full-width {
		grid-column: 1 / 3;
	}

	.hpf-validation-error {
		color: red;
		text-align: right;
	}

	.hpf-validation-success {
		color: green;
		text-align: left;
	}

	.hpf-button {
		cursor: pointer;
	}
}

/*#endregion*/


/*#region $UTILITY*/

.hpf-hiddenPanel {
	display: none;
}

.hpf-hideText {
	font-size: 1px;
	letter-spacing: -100px;
	line-height: 1px;
	text-decoration: none;
	text-indent: -9999px;
}

.hpf-visibilityHidden,
.placeholder .hpf-placeholderHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.hpf-imageReplacement {
	display: block;
	font-size: 1px;
	letter-spacing: -100px;
	line-height: 1px;
	overflow: hidden;
	text-decoration: none;
	text-indent: -9999px;
}

.hpf-smallCaps {
	font-size: 78.5714%;
	text-transform: uppercase;
}

.hpf-clearFix::before,
.hpf-clearFix::after {
	content: ' ';
	display: table;
}

.hpf-clearFix::after {
	clear: both;
}

.hpf-show {
	display: block !important;
}

/*#endregion*/


/*#region $WIDTH < 48em*/
/* NOTE: Sample CSS collapsing down all our columns into a single stack for small screens.
		Replace with your project specific styles. Use 'em' instead of 'px' on media query to support zooming. */
/*
NOTE: These styles have been prevented from being applied (max-width:0px - for now) as a mobile, responsive site is not
required at the moment and these styles were causing an issue with the modal window on IE9. After the modal was loaded
and the cursor triggered a mouse over event on the modal window, these mobile styles were being applied to the background
for no known reason. It seems as if the modal window met the responsive media query requirements, and for some reason these
styles were being applied to not just the modal, but the regular site as well.
*/
@media (max-width: 0) {
	/*#region --$SharePoint OOB overrides*/
	body {
		overflow-y: scroll !important;
	}

	#s4-workspace {
		-webkit-overflow-scrolling: auto;
		height: auto !important;
		overflow: visible !important;
		/* remove touch overflow scrolling b/c removing scrollable divs in this view anyway */
		position: static;
		width: auto !important;
	}
	/* Required if workspace is of a fixed size

#s4-workspace {
	-webkit-overflow-scrolling: touch;
}

#s4-workspace > * {
	-webkit-transform: translateZ(0px);
}

*/
	#s4-ribbonrow,
	.ms-core-brandingText,
	#suiteLinksBox,
	#ms-help,
	#DeltaPlaceHolderPageDescription,
	#siteactiontd,
	#sideNavBox {
		display: none !important;
	}
	/*#region --$Header*/
	#suiteBarLeft {
		height: 40px;
	}

	#suiteBarLeft .ms-core-brandingText {
		padding-left: 15px;
	}

	#suiteLinksBox {
		float: right;
	}

	#suiteLinksBox .ms-core-suiteLink {
		line-height: 40px;
	}

	#Suite_ActiveLinkIndicator_Clip {
		top: 21px;
	}

	#DeltaSuiteBarRight .ms-welcome-root {
		height: 30px;
		padding: 5px 7px 5px 11px;
	}

	#s4-titlerow {
		height: auto;
		margin: 0;
		width: auto;
	}

	#titleAreaBox {
		margin: 0;
		padding: 0 15px;
		width: 100%;
	}

	#siteIcon {
		display: block;
		float: none;
		height: auto;
		left: 15px;
		line-height: normal;
		margin-right: 62px;
		min-width: 0;
		position: absolute;
		top: 4px;
	}

	#siteIcon img {
		max-height: 31px;
		max-width: none;
	}

	.ms-breadcrumb-box {
		clear: both;
		display: block;
		height: auto;
	}

	.hpf-compressedNav {
		float: right;
		margin-right: 5px;
	}

	.hpf-compressedNav a {
		@include display-inline-block;
		background: url('../../../../Images/FNHA.Internet.WebSite/Publishing/nav-launcher.png') no-repeat center 15px;
		height: 12px;
		padding: 14px;
		width: 12px;
	}

	.hpf-compressedNav .hpf-nav-launcher {
		width: 13px;
	}

	.hpf-compressedNav .hpf-search-launcher {
		background-image: url('../../../../Images/FNHA.Internet.WebSite/Publishing/search-launcher.png');
	}

	#DeltaTopNavigation {
		display: none;
	}
	/*#DeltaTopNavigation {
	height: 0;
}

	#DeltaTopNavigation ul.root {
		height: 0;
		overflow: hidden;
		-moz-transition: 250ms ease-out;
		-o-transition: 250ms ease-out;
		-webkit-transition: 250ms ease-out;
		transition: 250ms ease-out;
		-webkit-perspective: 1000;
	}

	#DeltaTopNavigation.hpf-active {
		height: auto;
	}

		#DeltaTopNavigation.hpf-active ul.root {
			height: auto;
			max-height: 1000px;
		}*/
	#DeltaTopNavigation.hpf-active {
		display: block;
	}

	#DeltaTopNavigation ul,
	#DeltaTopNavigation li {
		display: block;
	}

	#DeltaTopNavigation ul li ul li {
		padding: 10px;
	}

	#DeltaTopNavigation ul.root > li > a,
	#DeltaTopNavigation ul li ul li ul {
		display: none;
	}


	#DeltaPlaceHolderSearchArea {
		float: none;
		height: 34px;
		left: 0;
		position: absolute;
		top: 0;
		visibility: hidden;
		width: auto;
	}

	#searchInputBox {
		float: none;
	}

	.ms-srch-sb {
		display: block;
		height: 34px;
		position: relative;
	}

	.ms-srch-sb > input {
		box-sizing: border-box;
		display: block;
		font-size: 20px;
		height: 30px;
		padding-right: 0;
		padding-top: 2px;
		width: 99%;
	}

	.ms-srch-sb > .ms-srch-sb-navLink {
		height: 32px;
		position: absolute;
		right: 0;
		top: 0;
		width: 24px;
	}

	.ms-srch-sb-navImg {
		left: -1px;
		top: -1px;
	}

	.ms-srch-sb-navImg:hover,
	.ms-srch-sb-navLink-menuOpen > .ms-srch-sb-navImg {
		left: -27px;
		top: -1px;
	}

	.ms-srch-sb-searchLink {
		display: none;
	}

	#DeltaPlaceHolderSearchArea.hpf-active {
		top: 35px;
		visibility: visible;
		width: 100%;
		/*position: static;
	visibility: visible;*/
	}

	#DeltaPlaceHolderSearchArea.hpf-active #searchInputBox {
		margin: 0 10px;
	}
	/*#endregion*/
	.ms-core-pageTitle {
		display: block;
	}

	#contentBox {
		margin-left: 0;
		margin-right: 0;
		min-width: 0;
	}

	#contentRow {
		margin: 0;
		padding: 0 15px;
		width: auto;
	}

	#sideNavBox {
		float: none;
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}
	/*#region --$Web parts*/
	.ms-webpart-cell-vertical,
	.ms-webpart-chrome-vertical {
		display: block;
	}

	.ms-webpart-titleText,
	.ms-webpart-titleText > a {
		white-space: normal;
	}

	.ms-webpart-titleText nobr {
		white-space: normal;
		word-wrap: break-word;
	}

	.ms-webpart-titleText span {
		white-space: normal;
	}



	.hpf-footer .hpf-footer-inner {
		margin: 0;
		width: auto;
	}
}

/*#endregion*/
