// reference/mixins/_images.scss

// TODO: Spread parameters currently fail in sass-lint. Will eventually be fixed, see below:
// https://github.com/tonyganch/gonzales-pe/issues/219


/// Adds SVG images to backgrounds, while adding fills, strokes and other SVG attributes to the inlined SVG.
///
/// @mixin
/// Note: by default, most SVGs will need to be manually edited since attributes are added to the root SVG
/// element. Child elements that have their own attributes won't be overwritten.
/// @section Image Mixins
/// @param $name The initial attribute name to add to the root SVG element. E.g. 'fill', 'stroke', 'opacity'.
/// @param $filename The filename of the SVG background image, without the extension.
/// @param $value The value of the initial attribute to add to the SVG inlining.
/// @param $width The background-size width of the image.
/// @param $height The background-size height of the image.
/// @param $position-x: The position in the x-axis for the background image.
/// @param $position-y: The position in the y-axis for the background image.
/// @param $repeat Whether or not to repeat the background.
/// @param $attributes... Additional attributes to add to the root SVG element.
@mixin background-image-svg(
	$filename,
	$name: null,
	$value: null,
	$width: 0,
	$height: 0,
	$position-x: null,
	$position-y: null,
	$repeat: no-repeat,
	$attributes...
) {
	@if $name == null and length($attributes) > 1 {
		background-image: svg-load('#{$url-images}/#{$filename}.svg', $attributes);
	} @elseif $name != null and length($attributes) > 1 {
		background-image: svg-load('#{$url-images}/#{$filename}.svg', $name=$value, $attributes);
	} @elseif $name != null {
		background-image: svg-load('#{$url-images}/#{$filename}.svg', $name=$value);
	} @else {
		background-image: svg-load('#{$url-images}/#{$filename}.svg');
	}

	background-position-x: $position-x;
	background-position-y: $position-y;
	background-repeat: $repeat;

	@if $width != 0 or $height != 0 {
		background-size: $width $height;
	}
}


/// A helper for the `background-image-svg` mixin that auto-selects fill as the first attribute.
///
/// @mixin
/// @section Image Mixins
/// @param $filename The filename of the SVG background image, without the extension.
/// @param $color The color of the initial fill to add to the SVG inlining.
/// @param $width The background-size width of the image.
/// @param $height The background-size height of the image.
/// @param $position-x: The position in the x-axis for the background image.
/// @param $position-y: The position in the y-axis for the background image.
/// @param $repeat Whether or not to repeat the background. Defaults to `no-repeat`.
/// @param $attributes... Additional attributes to add to the root SVG element.
@mixin background-image-fill($filename, $color: transparent, $width: 0, $height: 0, $position-x: null, $position-y: null, $repeat: no-repeat, $attributes...) {
	@include background-image-svg($filename, fill, $color, $width, $height, $position-x, $position-y, $repeat, $attributes...);
}


/// A helper for the `background-image-svg` mixin that auto-selects stroke as the first attribute.
///
/// @mixin
/// @section Image Mixins
/// @param $filename The filename of the SVG background image, without the extension.
/// @param $color The color of the initial stroke to add to the SVG inlining.
/// @param $width The background-size width of the image.
/// @param $height The background-size height of the image.
/// @param $position-x: The position in the x-axis for the background image.
/// @param $position-y: The position in the y-axis for the background image.
/// @param $repeat Whether or not to repeat the background. Defaults to `no-repeat`.
/// @param $attributes... Additional attributes to add to the root SVG element.
@mixin background-image-stroke($filename, $color: transparent, $width: 0, $height: 0, $position-x: null, $position-y: null, $repeat: no-repeat, $attributes...) {
	@include background-image-svg($filename, stroke, $color, $width, $height, $position-x, $position-y, $repeat, $attributes...);
}


/// Adds SVG images to backgrounds, while inlining them using PostCSS plugins.
///
/// @mixin
/// @section Image Mixins
/// @deprecated
/// @param $filename The filename of the SVG background image, without the extension.
/// @param $width The background-size width of the image.
/// @param $height The background-size height of the image.
/// @param $repeat Whether or not to repeat the background.
/// @param $position-x: The position in the x-axis for the background image.
/// @param $position-y: The position in the y-axis for the background image.
/// @example
///  @include background-image('header/logo', 80px, 30px);
///  @include background-image('backgrounds/zigzag', 100px, 50px, repeat-x);
///  @include background-image('icons/arrow', 13px, 13px, no-repeat, 50%, 50%);
@mixin background-image($filename, $width: 0, $height: 0, $repeat: no-repeat, $position-x: null, $position-y: null) {
	@include background-image-svg($filename, null, null, $width, $height, $position-x, $position-y, $repeat);
}


/// Change the background image, without the other background properties.
///
/// @mixin
/// @section Image Mixins
/// @deprecated
/// @param $filename The filename of the SVG background image, without the extension.
/// @example
///  &:hover {
///      @include background-image-change('icons/like-hover');
///  }
@mixin background-image-change($filename) {
	@include background-image-svg($filename);
}
