// reference/mixins/_helpers.scss

@import '../variables/reference';

@import 'media-queries';

/// Interpolates between any two values and breakpoints. Based on https://css-tricks.com/between-the-lines/
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-container {
///      @include between-xs-and-xxl(padding, 20px, 40px);
///  }
@mixin between-x-and-y($property, $from-breakpoint-key, $from-value, $to-breakpoint-key, $to-value) {
	@include media-min($from-breakpoint-key) {
		@include media-max($to-breakpoint-key) {
			#{$property}: between(
				map-get($breakpoints, $from-breakpoint-key),
				$from-value,
				map-get($breakpoints, $to-breakpoint-key),
				$to-value
			);
		}
	}

	@include media-min($to-breakpoint-key) {
		#{$property}: $to-value;
	}
}


/// Interpolates between two values for xs and xxl breakpoints. Based on https://css-tricks.com/between-the-lines/
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-container {
///      @include between-xs-and-xxl(padding, 20px, 40px);
///  }
@mixin between-xs-and-xxl($property, $from-value, $to-value) {
	@include between-x-and-y($property, $breakpoint-xs-key, $from-value, $breakpoint-xxl-key, $to-value);
}


/// Resets the commonly reset <button> tag properties
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-container__button {
///      @include button-reset;
///  }
@mixin button-reset(
	$background-color: transparent,
	$background-color-hover: transparent,
	$background-color-active: transparent,
	$border: 0,
	$display: inline-block,
	$margin: 0,
	$padding: 0
) {
	background-color: $background-color;
	border: $border;
	box-shadow: none;
	cursor: pointer;
	display: $display;
	line-height: 1;
	margin: $margin;
	min-width: 0;
	padding: $padding;

	&:active {
		background-color: $background-color-active;
	}

	&:hover {
		background-color: $background-color-hover;
	}
}


/// Removes bullets and white-space from lists.
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-list {
///      @include clean-list;
///  }
@mixin clean-list {
	list-style: none;
	margin: 0;
	padding: 0;
}


/// Add the necessary properties for absolutely positioning before and after content.
///
/// @mixin
/// @section Helper Mixins
/// @example
/// @include content;
@mixin content {
	content: '';
	display: block;
	position: absolute;
}


/// Forces an element to contain all float child elements.
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-block {
///       &__row {
///           @include clear-fix;
///       }
///
///       &__column {
///           float: left;
///       }
///  }
@mixin clear-fix {
	zoom: 1;

	&::before,
	&::after {
		content: ' ';
		display: table;
	}

	&::after {
		clear: both;
	}
}


/// Fixes a bug wherein RTE style text is invisible upon initial page creation
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-block {
///       @include fix-invisible-text;
///  }
@mixin fix-invisible-text {
	.#{$global-prefix}-editMode & {
		@keyframes animation {
			from {
				text-rendering: auto;
			}

			to {
				text-rendering: optimizeLegibility;
			}
		}


		animation-duration: .5s;
		animation-iteration-count: 1;
		animation-name: animation;
		text-rendering: optimizeLegibility;
	}
}


/// Extends the element to the width of the page.
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-block {
///       @include full-bleed;
///  }
@mixin full-bleed {
	$size: $grid-outside-gutter-width;

	margin-left: -$size;
	margin-right: -$size;

	@include media-min(xl) {
		left: 50%;
		margin-left: 0;
		margin-right: 0;
		position: relative;
		transform: translateX(-50%);
		width: 100vw;
	}
}


/// Extends the element to the width of the page, with a background color, gradient or image.
///
/// @mixin
/// @section Helper Mixins
/// @param $color The background color of the element.
/// @param $color The background property of the element (gradient or background image).
/// @example
///  .go-block {
///      @include full-bleed-background($accent-primary);
///  }
@mixin full-bleed-background($color: transparent, $background: null) {
	@if $background {
		background: $background;

		&::before {
			background: $background;
			background-position: top;
			background-size: cover;
		}
	} @else {
		background-color: $color;

		&::before {
			background-color: $color;
		}
	}

	&,
	& > * {
		position: relative;
	}

	& > * {
		z-index: 1;
	}

	&::before {
		bottom: 0;
		content: '';
		left: 50%;
		margin-left: 0;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 100vw;
	}
}


/// Hide any text within an element and only show the background image.
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-block__icon {
///      @include image-replacement;
///      @include background-image-fill('icon/magnifying-glass', $white, 14px, 14px);
///  }
@mixin image-replacement {
	border: 0;
	display: block;
	overflow: hidden;
	text-indent: -9999px;
}


/// CSS property that prevent text overflowing it's container. IE has it's own property hence this mixin
///
/// @mixin
/// @section Helper Mixins
/// @example
///  .go-article {
///      @include prevent-text-overflow;
///  }
@mixin prevent-text-overflow {
	word-break: break-word;

	// Need this for IE
	word-wrap: break-word;
}


/// Hide text visually, but still allow screen readers to read it.
///
/// @mixin
/// @section Helper Mixins
/// @example
///   .go-block__legend {
///       @include sr-only;
///    }
@mixin sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
