﻿//style.css contains the custom CSS for fnha.com

//Authors: John Allan (and Chris Parsons from hab.com)

@import 'variables';

// Border radius
@mixin border-radius($radius) {
	background-clip: padding-box;
	border-radius: $radius;
}

@mixin background-clip($property) {
	background-clip: $property;
}

// Box sizing
@mixin box-sizing($property) {
	box-sizing: $property;
}

@mixin text-overflow($property, $w, $h) {
	display: block;
	height: $h;
	overflow: hidden;
	text-overflow: $property;
	white-space: nowrap;
	width: $w;
}

// Creates a linear gradient, specifying a fallback color and top bottom positions
@mixin linear-gradient($background-color, $start-color, $stop-color, $start-pos: 0%, $bottom-pos: 100%) {
	background-color: $background-color;
	background-image: linear-gradient(to bottom, $start-color $start-pos, $stop-color $bottom-pos);
}

@mixin linear-gradient-with-filter($color, $start, $stop, $start-pos, $bottom-pos) {
	@include linear-gradient($color, $start, $stop, $start-pos, $bottom-pos);
}

// Creates a horizontal linear gradient using the average color as a fallback
@mixin carousel-gradient($outer, $inner) {
	background-color: mix($outer, $inner);
	background-image: linear-gradient(to right, $outer 0%, $inner 40%, $inner 60%, $outer 100%);
}

@mixin ltr-linear-gradient($left, $middle, $right) {
	background-color: $middle;
	background-image: linear-gradient(left, $left 0%, $middle 50%, $right 100%);
}

// Creates a box shadow
@mixin box-shadow($shadow) {
	box-shadow: $shadow;
}

// Column count
@mixin column-count($columns) {
	column-count: $columns;
}

// Transform
@mixin transform($transform) {
	transform: $transform;
}

// Image replacement
@mixin image-replacement {
	border: 0;
	display: block;
	overflow: hidden;
	text-indent: -9999px;
}

// Visually hidden
@mixin visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Absolutely positioned element to take up all space
@mixin position-absolute-all($area: 0) {
	bottom: $area;
	left: $area;
	right: $area;
	top: $area;
}

// Display inline-block with IE7 fallback
@mixin display-inline-block {
	display: inline-block;
}

// Default selection
@mixin selection {
	background: #b3d4fc;
	text-shadow: none;
}

// Arrows
@mixin arrow($size, $color, $direction, $offset, $border-size: 0, $border-color: inherit) {
	position: relative;

	&::after,
	&::before {
		content: '';
		height: 0;
		position: absolute;
		width: 0;
	}

	$m-size: $size + ($border-size * 2);

	@if $direction == top {
		&::after {
			border-bottom: $size solid $color;
			border-left: $size solid transparent;
			border-right: $size solid transparent;
			bottom: 100%;
			left: $offset;
			margin-left: -$size;
		}

		&::before {
			border-bottom: $m-size solid;
			border-bottom-color: $border-color;
			border-left: $m-size solid transparent;
			border-right: $m-size solid transparent;
			bottom: 100%;
			left: $offset;
			margin-left: -$m-size;
		}
	} @else if $direction == bottom {
		&::after {
			border-left: $size solid transparent;
			border-right: $size solid transparent;
			border-top: $size solid $color;
			left: $offset;
			margin-left: -$size;
			top: 100%;
		}

		&::before {
			border-left: $m-size solid transparent;
			border-right: $m-size solid transparent;
			border-top: $m-size solid;
			border-top-color: $border-color;
			left: $offset;
			margin-left: -$m-size;
			top: 100%;
		}
	} @else if $direction == right {
		&::after {
			border-bottom: $size solid transparent;
			border-left: $size solid $color;
			border-top: $size solid transparent;
			left: 100%;
			margin-top: -$size;
			top: $offset;
		}

		&::before {
			border-bottom: $m-size solid transparent;
			border-left: $m-size solid;
			border-left-color: $border-color;
			border-top: $m-size solid transparent;
			left: 100%;
			margin-top: -$m-size;
			top: $offset;
		}
	} @else if $direction == left {
		&::after {
			border-bottom: $size solid transparent;
			border-right: $size solid $color;
			border-top: $size solid transparent;
			margin-top: -$size;
			right: 100%;
			top: $offset;
		}

		&::before {
			border-bottom: $m-size solid transparent;
			border-right: $m-size solid;
			border-right-color: $border-color;
			border-top: $m-size solid transparent;
			margin-top: -$m-size;
			right: 100%;
			top: $offset;
		}
	}
}


/* FNHA styles */

@mixin item-dividers($spacing, $border: $light-blue-grey) {

	& {
		position: relative;
	}

	&::after {
		background: $border;
		bottom: (-1px * ($spacing/2px));
		content: ' ';
		height: 1px;
		left: 0;
		position: absolute;
		width: 100%;
	}
}

@mixin item-dividers-before($spacing, $border: $light-blue-grey) {

	& {
		position: relative;
	}

	.cssgradients &::before {
		background: $border;
		content: ' ';
		height: 1px;
		left: 0;
		position: absolute;
		top: (-1px * ($spacing/2px));
		width: 100%;
	}
}

@mixin kill-dividers {
	&::after {
		display: none;
	}
}

@mixin content-image {
	@include box-shadow(0 7px 7px -7px rgba(0, 0, 0, .2));
	background: $white;
	border: 1px solid #cac7c3 !important;
	margin-bottom: 7px;
	padding: 3px;
}


// Buttons

@mixin button {
	@include linear-gradient($green, $green, #5d6e61);
	@include border-radius(4px);
	@include box-shadow(0 1px 2px -1px #222);
	border-bottom: 0;
	border-top-color: $green;
	color: $white;
	font-weight: $font-weight-light;
	position: relative;

	&::before {
		@include border-radius(4px);
		border-top: 1px solid #94a396;
		content: ' ';
		height: 10px;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:link,
	&:visited {
		color: $white;
	}

	&:hover {
		@include linear-gradient(#273a22, #708573, #273a22);
		text-decoration: none;
	}
}

@mixin button-disabled {
	&,
	&:hover {
		background: #435547;
		border-color: #121814 #334137 #3d4d41;
		cursor: default;
	}

	&::before {
		border-top-color: #2f3c32;
	}
}

/*This mixin handles the icons for different file types and bullets. */
@mixin icon-sprite($index) {
	background: url('#{$brand-images}/bullet-sprite.png') scroll no-repeat 0 ((-500px * $index) + 3px) transparent;
}

/*This deals with the aside webpart */
@mixin aside-web-part-h2 {
	color: $dark-blue;
	font-size: $font-size-5;
	font-weight: $font-weight-bold;
	margin-bottom: 5px;
}
