﻿//style.css contains the custom CSS for fnha.com
//Authors: John Allan


//colors
$black: #000;
$black-blue: #1e1f28;

$darker-blue-grey: #3d405e;
$dark-blue-grey: #3a4e5c;
$blue-grey: #bbcad2;
$light-blue-grey: #d9e0e4;

$dark-blue: #292c39;
$darker-blue: #1c2e3b;
$blue: #243a4a;
$light-blue: #7d9aaa;

$border-grey: #dfdfdf;
$border-light-grey: #848484;

$whiter-grey: #e7e8ea;
$white-grey: #e1e1e3;
$lighter-grey: #dbdde0;
$light-grey: #a6a7ab;
$grey: #acadad;

$darker-white: #e8e8e8;
$dark-white: #f2f2f2;
$white: #fff;
$bg-white: #fafafa;

$warning-red: #f00;

$light-brown: #b6b1a9;
$dark-brown: #5b5142;

$orange: #e17000;

$green: #718674;

$teal: #01acc6;
$red: #b1272f;
$purple: #30334c;

//font family
$font-family-sans: 'Open Sans', Helvetica, Arial, sans-serif;

//font sizes
$font-size-1: 30px;
$font-size-2: 24px;
$font-size-3: 21px;
$font-size-4: 18px;
$font-size-5: 15px;
$font-size-6: 14px;
$font-size-7: 13px;
$font-size-8: 12px;
$font-size-9: 11px;
$font-size-10: 10px;
$font-size-11: 9px;

//font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Megamenu variables
$menu-width: 272px;
$menu-ite-drop-down-width: 35px;

//paths
$brand-images: 'images';

// media query breakpoints
$screen-sm-1: 767px;
$screen-sm: 768px;
$screen-md: 1024px;

// media queries
$media-screen-sm: '(max-width: #{$screen-sm-1})';
$media-screen-md: '(max-width: #{$screen-md})';
$media-screen-sm-to-md: '(max-width: #{$screen-md}) and (min-width: #{$screen-sm})';
