﻿@import 'reference/reference';

$block: 'go-accordion';

.#{$block} {
	@include accordion;

	body:not(.hpf-editMode) & {
		&:not(.go-accordion--expanded) > :not(:first-child):not(.go-accordion-heading):not(.go-accordion__heading) {
			@include sr-only;
		}
	}

	&__container {
		overflow: auto;
		padding: sp(1) 0 0;
		position: relative;
	}

	&__list {
		@include clean-list;
		padding-left: 0 !important;
	}

	&__content {
		display: none;
		overflow: auto;
		padding: sp(1) 0;
		text-align: left;

		.ms-webpart-chrome-title {
			display: none; // Hide panel content title, given title is in a panel now
		}

		.ms-WPBorder,
		.ms-WPBorderBorderOnly {
			border: 0;
		}

		&--active,
		.#{$block}--expanded & {
			display: block;
		}

		.go-label {
			color: #455560;
			display: block;
			margin-bottom: 7px;
		}
	}

	h2.go-accordion-heading,
	h2.go-accordion__heading,
	> h2:first-child {
		@include accordion-title-collapsed;
	}

	&--current,
	&--expanded {
		padding-bottom: sp(2) !important;

		.go-snippet-editor & {
			padding-bottom: 0;
		}

		h2.go-accordion-heading,
		h2.go-accordion__heading,
		> h2:first-child {
			@include accordion-title-expanded;
		}
	}
}
