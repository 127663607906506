﻿@import 'variables';
@import 'mixins';

#s4-workspace {
	#s4-bodyContainer {
		position: relative;
	}
}

// Hide mobile controls
.mp-mobilecontrols,
.menu-item-handle {
	display: none;
}

// Hide the new megamenu by default
ul.megaMenu {
	display: none;
}

// Hide the copied header menu by default
ul.hpf-utilityNav.secondary-menu,
ul.hpf-utilityNav.social {
	display: none;
}

@media #{$media-screen-md} {
	body {
		overflow-x: hidden;
	}

	.csstransforms3d {
		.megamenu--active {
			@include transform(translate3d(-$menu-width, 0, 0));

			#DeltaTopNavigation {
				.ms-core-listMenu-horizontalBox {
					transition: transform .5s ease;
				}
			}
		}

		#s4-bodyContainer {
			transition: transform .5s ease;

			#DeltaTopNavigation {
				.ms-core-listMenu-horizontalBox {
					@include transform(translate3d($menu-width, 0, 0));
				}
			}
		}

		#searchInputBox {
			@include transform(translate3d(-100%, 0, 0));
			transition: transform .5s ease-out;
		}

		.globalsearch--active {
			#searchInputBox {
				@include transform(translate3d(0, 0, 0));
				transition: transform .5s ease-out;
			}
		}

		// Hide the search when the megamenu is on screen
		.globalsearch--active.megamenu--active {
			#searchInputBox {
				@include transform(translate3d(-100%, 0, 0));
			}
		}
	}

	// Use absolute positioning for browsers that don't support 3d transforms
	.no-csstransforms3d {
		#searchInputBox {
			left: -100%;
		}

		.globalsearch--active {
			#searchInputBox {
				left: 0;
			}
		}

		.globalsearch--active.megamenu--active {
			#searchInputBox {
				left: $menu-width;
			}
		}
	}

	.menuItemHandle {
		background-color: $dark-blue;
		display: block;
		height: 100%;
		position: absolute;
		right: 0;
		text-indent: -9999em;
		top: 0;
		width: $menu-ite-drop-down-width;

		&::after {
			background-image: url('#{$brand-images}/mega-sprite.png');
			background-position: 0 0;
			background-repeat: no-repeat;
			content: '';
			height: 10px;
			left: 10px;
			position: absolute;
			top: 20px;
			width: 15px;
		}
	}

	ul.hpf-utilityNav.secondary-menu {
		background-color: $darker-blue;
		border-bottom: 1px solid $border-light-grey;
		border-top: 1px solid $border-light-grey;
		display: block;
		float: none;
		margin: 0;
		padding: 12px 0;

		li {
			display: block;
			float: none;
			margin: 0;

			a {
				display: block;
				font-size: $font-size-6;
				line-height: 1;
				margin: 0;
				padding: 12px 20px;
			}
		}
	}

	ul.hpf-utilityNav.social {
		display: block;
		float: none;
		margin: 0 auto;
		margin-top: 20px;
		padding-bottom: 60px;
		width: 187px;

		li {
			float: left;
			margin-right: 9px;
			padding: 0;

			&:last-child {
				margin-right: 0;
			}
		}

		.hpf-headerSocialIcon {
			background: url('#{$brand-images}/mega-sprite.png') no-repeat 0 0;
			// Shrinking background size by ~33% for mobile
			background-size: 270px 270px;
			height: 40px;
			margin-right: 0;
			width: 40px;

			&.hpf-facebook {
				background-position: -38px 0;
			}

			&.hpf-twitter {
				background-position: -84px 0;
			}

			&.hpf-linkedin {
				background-position: -129px 0;
			}

			&.hpf-youtube {
				background-position: -175px 0;
			}

			&.hpf-instagram {
				background-position: -219px 0;
			}
		}
	}

	// Show burger icon and search icon
	.mp-mobilecontrols {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
	}

	.ms-mpSearchBox {
		position: static;
	}

	.ms-srch-sb {
		height: 30px;
		width: 100%;

		input {
			font-size: $font-size-6;
			height: 100%;
			line-height: 30px;
			margin: 0;
			padding: 0 10px;
			width: 100%;
		}

		> .ms-srch-sb-searchLink {
			display: none;
		}
	}

	// Hide and style search input
	#searchInputBox {
		background-color: $blue;
		box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .75);
		box-sizing: border-box;
		left: 0;
		margin: 0;
		padding: 10px 13px;
		position: absolute;
		top: 65px;
		width: 100%;
		z-index: 1000;
	}

	.mp-mobilecontrols-nav {
		background-position: 10px -210px;
		right: 0;
	}

	.mp-mobilecontrols-search {
		background-position: 10px -283px;
		right: 46px;
	}

	.mp-mobilecontrols-search,
	.mp-mobilecontrols-nav {
		background-image: url('#{$brand-images}/mega-sprite.png');
		background-repeat: no-repeat;
		cursor: pointer;
		display: block;
		height: 64px;
		overflow: hidden;
		position: absolute;
		text-indent: -9999em;
		top: 0;
		width: 50px;
	}

	// Hide "contact us", "careers" pages navigation links & social media icons
	.hpf-utilityNav,
	.hcf-HeaderNav {
		display: none;
	}

	.hpf-publishing {
		#s4-titlerow {
			border-bottom: 0;
			height: auto;
			padding-bottom: 0;
			padding-top: 0;
		}
	}


	// Remove decorational top border and reset bg color
	#s4-titlerow {
		background-color: $white;
		background-image: none;
		border-top: 0;
		margin: 0;

		.ms-tableRow {
			display: block;
		}

		.ms-breadcrumb-box {
			display: block;
		}
	}

	header {
		border-bottom: 1px solid $blue;
		height: 64px;

		.ms-srch-sb-border,
		.ms-srch-sb-borderFocused {
			border: 0;
			display: block;
			padding: 0;

			&:hover {
				border: 0;

				input {
					margin: 0;
				}
			}
		}
	}


	#DeltaTopNavigation {
		padding-top: 0;

		.ms-core-listMenu-horizontalBox {
			background-color: $blue;
			box-shadow: none;
			min-height: 100vh;
			position: absolute;
			right: 0;
			top: 0;
			width: $menu-width;
			z-index: 10001;
		}

		.ms-core-listMenu-root {
			display: none;
		}
	}

	.megamenu--active {
		#DeltaTopNavigation {
			.ms-core-listMenu-horizontalBox {
				box-shadow: -2px 0 6px -3px $grey;
			}
		}
	}

	/********************************************************************************/

	.ms-core-listMenu-horizontalBox ul,
	.ms-core-listMenu-horizontalBox li,
	.megaMenuSubtitle {
		display: block;
	}

	.megaMenuSubtitle {
		color: $grey;
		font-size: $font-size-8;
		line-height: 1;
		margin-top: 9px;
	}


	ul.megaMenu {
		display: block;
		margin: 0;
	}

	ul.megaMenu--lvl2,
	ul.megaMenu--lvl3,
	ul.megaMenu--lvl4 {
		display: none;
	}

	.megaMenuItem {
		box-sizing: border-box;
		display: block;
		white-space: normal;
		width: 100%;
	}

	.megaMenuItem--lvl1 {
		> .megaMenuItemWrapper {
			border-top-color: $border-light-grey;
		}
	}

	.megaMenuItem--lvl1.active {
		background-color: $white;

		& > ul {
			display: block;
		}

		.megaMenuItemTitle--lvl1:visited {
			color: $blue;
		}

		.megaMenuItemTitle--lvl1 {
			color: $blue;

			~ .menuItemHandle {
				background-color: $white;

				&::after {
					background-position: 0 -116px;
				}
			}
		}

		.megaMenuItemTitle--lvl2 {
			background-color: $white;
			color: $blue;

			~ .menuItemHandle {
				background-color: $dark-white;

				&::after {
					background-position: 0 -60px;
					height: 6px;
					left: 13px;
					top: 19px;
					width: 9px;
				}
			}
		}
	}

	.megaMenuItem--lvl2.active {
		.megaMenuItemTitle {
			background-color: $dark-white;
		}

		.megaMenuItemTitle--lvl2 {
			background-color: $dark-white;
			font-weight: bold;

			~ .menuItemHandle {
				background-color: $darker-white;

				&::after {
					background-position: 0 -176px;
				}
			}
		}

		.megaMenu--lvl3 {
			display: block;
		}
	}

	.megaMenuItemTitle--lvl3 {
		~ .menuItemHandle {
			background-color: $darker-white;

			&::after {
				background-position: 0 -60px;
				height: 6px;
				left: 13px;
				top: 19px;
				width: 9px;
			}
		}
	}

	.megaMenuItem--lvl3.active {
		.megaMenuItemTitle--lvl3 {
			font-weight: $font-weight-bold;
		}

		.megaMenuItemTitle {
			~ .menuItemHandle {
				&::after {
					background-position: 0 -176px;
				}
			}
		}

		.megaMenu--lvl4 {
			display: block;
		}
	}

	.megaMenuItemWrapper {
		border-top: 1px solid $border-grey;
		position: relative;

		&:hover {
			cursor: pointer;
		}

		> .megaMenuItemTitle {
			border: 0;
		}
	}

	.hasChildren > .megaMenuItemWrapper .megaMenuItemTitle {
		width: $menu-width - $menu-ite-drop-down-width;
	}

	/* Item titles */
	.megaMenuItemTitle {
		border-top: 1px solid $border-grey;
		box-sizing: border-box;
		color: $dark-white;
		display: block;
		line-height: 1;
		padding: 17px 20px 18px;

		&:hover {
			text-decoration: none;
		}

		&.current {
			font-weight: $font-weight-bold;
		}
	}

	.megaMenuItemTitle--lvl1 {
		font-size: 17px;

		&:visited {
			color: $dark-white;
		}
	}

	.megaMenuItemTitle--lvl2 {
		font-size: $font-size-6;
		padding-bottom: 13px;
		padding-top: 15px;
	}

	.megaMenuItemTitle--lvl3,
	.megaMenuItemTitle--lvl3:visited,
	.megaMenuItemTitle--lvl4,
	.megaMenuItemTitle--lvl4:visited {
		color: $green;
		font-size: $font-size-6;
		padding-bottom: 13px;
		padding-top: 16px;
	}

	.megaMenuItemTitle--lvl4,
	.megaMenuItemTitle--lvl4:visited {
		color: $blue;
		padding-left: 30px;
	}
	/***********************************************/
}

/*#region $UTILITYNAVIGATION*/

.hpf-utilityNav a {
	color: $light-blue;
}

.hpf-utilityNav li a.hpf-headerSocialIcon {
	background-image: url('#{$brand-images}/mega-sprite.png');
}

.ms-srch-sb-border,
.ms-srch-sb-borderFocused,
.ms-srch-sb-border:hover {
	@include border-radius(3px);
	@include box-shadow(inset 0 0 10px #eee);
	background: $white;
	border: 1px solid $light-brown;
	color: $light-brown;

	input {
		//margin-left: 8px;

		&.ms-helperText {
			color: $light-brown;
		}
	}
}

//.ms-srch-sb input {
//width: 180px;
//}

/*#endregion*/

/*#region $RETINA*/
@media (min-resolution: 120dpi) {
	.menuItemHandle {
		&::after {
			background-image: url('#{$brand-images}/mega-sprite-2x.png');
			background-size: 29px 401px;
		}
	}

	.mp-mobilecontrols-nav,
	.mp-mobilecontrols-search {
		background-image: url('#{$brand-images}/mega-sprite-2x.png');
		background-size: 29px 401px;
	}

	.hpf-newsItem__category {
		&::before {
			background-image: url('#{$brand-images}/mega-sprite-2x.png');
			background-position: -1px -378px;
			background-size: 29px 401px;
		}
	}
}
/*#endregion*/

// iPad1
@media only screen and (device-width: 768px) and (device-height: 1024px) and (device-pixel-ratio: 1) {
	#s4-workspace {
		overflow: hidden;
	}
}

// iPhone4 has problems with overflow-x
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (min-device-pixel-ratio: 2) {
	#s4-workspace {
		overflow: hidden;
	}
}
