﻿// reference/variables/_tabs.scss

@import 'colors';
@import '../variables/typography';

$tabs-background-color: transparent !default;
$tabs-border-bottom-color: transparent !default;
$tabs-border-bottom-width: 4px !default;
$tabs-font-weight: $font-weight-medium !default;
$tabs-link-color: $grey-dark !default;
$tabs-link-color-hover: $grey-black !default;
$tabs-selected-color: $accent-primary-dark !default;
$tabs-selected-background-color: transparent !default;
$tabs-selected-border-bottom-color: $tabs-selected-color !default;
$tabs-text-align: left !default;
