﻿@import '../../../../brand-reference';
@import '../variables/reference';
@import '../mixins/reference';

$block: '#{$global-prefix}-snippet-editor';

.#{$block} {
	&-container {
		background: $white;
		box-sizing: border-box;
		left: 50%;
		max-width: calc(100% - (#{$grid-gutter-width} * 2));
		min-height: 250px;
		padding: sp(3) sp(3) sp(2);
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 1000px;
		z-index: $z-index-regular;
	}

	&-component {
		margin-top: sp(3);
		max-height: 500px;
		overflow: auto;
	}

	&-button-group {
		margin-top: sp(2);
		text-align: right;
	}

	&-close {
		@include button-reset;
		@include image-replacement;
		@include background-image-stroke('icons/close', $grey, sp(2), sp(2), center, center);

		height: sp(2);
		position: absolute;
		right: sp(2);
		top: sp(2);
		width: sp(2);
	}

	&-overlay {
		background: transparentize(#999, .6);
		height: 100%;
		left: -1000px;
		position: absolute;
		top: 0;
		width: calc(100vw + (1000px - #{$grid-outside-gutter-width} - 1px));
		z-index: calc(#{$z-index-regular} - 1);
	}

	&-confirmation-dialog {
		text-align: center;

		&__warning-message {
			font-size: $font-size-medium;
			margin: 0 0 sp(2);
		}

		&__container {
			// Need this to make sure modal is sized correctly
			min-height: 0;
		}
	}
}
