﻿@import '../../../../brand-reference';

$block: '#{$global-prefix}-snippet';

.#{$block} {
	&-enhanced {
		.ms-rtestate-write & {
			border: 1px solid #a6a6a6;
			box-sizing: border-box;
			margin-bottom: sp(3);
			min-height: 50px;
			padding: sp(5) sp(1) sp(1);
			position: relative;
			transition: border .3s ease;

			&::before {
				align-items: center;
				box-sizing: border-box;
				content: 'Snippet';
				display: flex;
				height: sp(4);
				left: 0;
				padding: 0 sp(1);
				position: absolute;
				top: 0;
				width: 100%;
			}

			&::after {
				background: transparent;
				content: '';
				cursor: pointer;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;
			}

			&--active,
			&:hover {
				border-color: $grey-dark;

				&::after {
					background: transparentize($white, .4);
				}
			}

			#ms-rterangecursor-start,
			#ms-rterangecursor-end {
				display: none;
			}

			&[data-name='AccordionGroup'] {
				&::before {
					content: 'Accordion Group';
				}
			}

			&[data-name='Tabs'] {
				&::before {
					content: 'Tabs';
				}
			}
		}
	}

	&-accordion,
	&-tabs {
		&__add,
		&__delete {
			display: inline-block;
			font-size: $font-size-smaller;
			text-transform: uppercase;
		}

		&__delete {
			$icon-add-size: 14px;
			bottom: sp(4);
			padding-left: calc(#{$icon-add-size} + #{sp(1)});
			position: absolute;

			&::before {
				@include background-image-fill('images/icons/delete', $orange, $icon-add-size, $icon-add-size);
				// sass-lint:disable-all
				background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23e17000' width='13' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.25 2.625h-.875v10.063a1.317 1.317 0 01-.8 1.21c-.16.068-.33.102-.512.102h-7a1.317 1.317 0 01-1.21-.8 1.288 1.288 0 01-.103-.512V2.624H.875V1.75h3.5V.875a.848.848 0 01.256-.619A.848.848 0 015.25 0h2.625a.848.848 0 01.619.256.848.848 0 01.256.619v.875h3.5v.875zm-7-.875h2.625V.875H5.25v.875zm5.25.875H2.625v10.063a.42.42 0 00.13.307.42.42 0 00.308.13h7a.42.42 0 00.307-.13.42.42 0 00.13-.307V2.624zm-5.25 8.75h-.875v-7h.875v7zm1.75 0h-.875v-7H7v7zm1.75 0h-.875v-7h.875v7z' fill-rule='evenodd'/%3E%3C/svg%3E");
				// sass-lint:enable-all
				content: '';
				height: $icon-add-size;
				left: 0;
				position: absolute;
				width: $icon-add-size;
			}
		}
	}
}
