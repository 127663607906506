﻿@import '../../../../brand-reference';
@import '../variables/reference';
@import '../mixins/reference';

$block: '#{$global-prefix}-snippet-actions';

.#{$block} {
	$icon-size: 14px;

	background: $grey-darker;
	color: $white;
	opacity: 0;
	padding: sp(1);
	position: absolute;
	//transition: opacity .3s ease;
	z-index: 1;

	&--active {
		opacity: 1;
	}

	&__button {
		@include button-reset;
		@include color-states($white, $white, $white);
		@include image-replacement;

		display: inline-block;
		font-size: $font-size-small;
		font-weight: $font-weight-medium;
		height: sp(4);
		padding: sp(1);
		text-transform: uppercase;
		width: sp(4);

		&:hover {
			text-decoration: underline;
		}

		&-delete,
		&-edit {
			position: relative;

			&::before {
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		&-edit {
			&::before {
				@include background-image-fill('icons/edit', $white, $icon-size, $icon-size, center, center);
			}
		}

		&-delete {
			margin-left: sp(1);

			&::before {
				@include background-image-fill('icons/delete', $white, $icon-size, $icon-size, center, center);
			}
		}
	}
}
