﻿// reference/variables/_global.scss

/// @nuclide Border Radius
/// @section Variables > Global
$global-border-radius: 2px !default;

/// @nuclide Maximum Width
/// @section Variables > Global
$global-max-width: 1280px !default;

/// @nuclide CSS Classname Prefix
/// @section Variables > Global
$global-prefix: 'go' !default;

/// @nuclide CSS Classname Prefix
/// @section Variables > Global
$global-prefix-custom: 'go' !default;

/// @nuclide RTE CSS Default Classname Prefix
/// @section Variables > Global
$global-rte-prefix-default: 'ms-rte' !default;

/// @nuclide RTE CSS Classname Prefix
/// @section Variables > Global
$global-rte-prefix: '#{$global-prefix}-rte' !default;

/// @nuclide General Transition
/// @section Variables > Global
$global-transition: all .1s ease-in !default;

/// @nuclide Vertical Rhythm Length
/// @section Variables > Global
$global-vertical-rhythm-length: 12px !default;
