﻿@import 'variables';
@import 'mixins';


// Global responsive images
.ms-rtestate-field {
	img,
	video,
	iframe {
		max-width: 100%;
	}

	iframe {
		overflow: scroll;
	}
}

// Change back to auto in edit mode so SharePoint icons are unaffected
.hpf-editMode {
	.ms-rtestate-field {
		img,
		video,
		iframe {
			max-width: none;
		}
	}
}

// Set to block instead of table in order to display at proper width
.ms-webpart-zone,
.ms-webpart-cell-vertical,
.ms-webpart-chrome-vertical,
.ms-webpart-chrome-title,
.ms-wpContentDivSpace {
	display: block;
	max-width: 100% !important; // overriding inline styles
}

// Full desktop layout with adjusted paddings
@media #{$media-screen-sm-to-md} {
	// Adjusting the content rotator spacing to fit on screen
	.hpf-bannerRotator {
		.hpf-mediaItem-body {
			padding: 10px 0;

			> * {
				padding-left: 15px;
			}
		}
	}

	.hpf-publishing {
		#sideNavBox {
			left: 15px;
			top: 100px;
		}

		.hpf-zone-panel.hpf-aside-ontop {
			left: -30px;
			top: 480px;
		}
	}

	.hpf-publishing-landing #sideNavBox,
	#sideNavBox.isHeroPage {
		top: 550px;
	}

	.hpf-flexsliderWrapper {
		.hpf-controls {
			display: none;
		}
	}

	// Adjusting panel rows to have padding
	.hpf-zone-panel {
		padding: 0 15px;

		&.hpf-zone-hero {
			padding: 0;

			.hpf-mediaItem-media {
				width: 65%;
			}
		}
	}

	// Adjusting footer and sub-footer to have padding
	.hpf-ankle,
	.hpf-footer {
		padding-left: 15px;
		padding-right: 15px;
	}
}
/********************** 768px <= width <= 1024px **********************/


/********************** 1024px **********************/
@media #{$media-screen-md} {
	.hpf-page-container {
		.hpf-zone-title {
			border: 0;
			padding-left: 15px;
		}
	}

	.hpf-zone-hero {
		border-top: 0;
	}

	.hpf-zone-panel #SearchBox {
		box-sizing: border-box;
		width: 100%;
	}

	/* Breadcrumbs */
	#hpf-breadcrumb {
		border: 0;

		> * > * > * > span {
			display: block;
			padding: 10px 15px;
		}

		span {
			span {
				padding: 0 3px;
			}
		}
	}
	/******************************/

	/************** Mailing list section styling ***************/
	.hpf-ankle {
		padding-bottom: 70px;

		.hpf-subscribe {
			margin: 0;
			padding: 0 15px;
			text-align: center;

			input {
				margin-bottom: 10px;
				width: 100%;
			}

			&.hpf-saving {
				background: none;
				position: relative;

				&::after {
					background: url('#{$brand-images}/loader-anim.gif') no-repeat 50% 0;
					content: '';
					height: 32px;
					left: 0;
					position: absolute;
					top: 83px;
					width: 100%;
				}
			}
		}

		h2 {
			margin-bottom: 25px;
		}
	}

	#hpf-subscribeButton {
		padding-left: 20px;
		padding-right: 20px;
	}

	.hpf-subscribeMessage {
		box-sizing: border-box;
		left: 0;
		padding: 0 15px;
		position: absolute;
		width: 100%;
	}
	/*********************************************************/

	.hpf-footer {
		border: 0;
		padding: 0 15px 40px;

		h2 {
			margin-top: 40px;
		}

		.hpf-cleanList {
			margin-bottom: 0;
		}
	}

	.hpf-popularLinks,
	.hpf-Feedback {
		margin-top: 0;
	}

	.hpf-Feedback {
		p:last-child {
			margin-top: 25px;
		}
	}

	.hpf-footer-contactUs {
		.hpf-button-big-alt1 {
			display: inline-block;
			font-size: $font-size-8;
			margin-bottom: 5px;
			margin-right: 5px;
			min-width: auto;

			&:last-child {
				margin: 0;
			}
		}
	}

	// Adjusting padding on the search page title
	.hpf-publishing-search {
		.hpf-zone-title {
			padding: 0 15px;
		}
	}

	.locationsList__item {
		border-right: 0;
	}
}
/********************** 1024px **********************/

// Mobile single column view
@media #{$media-screen-sm} {
	.hpf-grid-push-1,
	.hpf-grid-push-1:first-child,
	.hpf-grid-push-2,
	.hpf-grid-push-2:first-child,
	.hpf-grid-push-3,
	.hpf-grid-push-3:first-child,
	.hpf-grid-push-4,
	.hpf-grid-push-4:first-child,
	.hpf-grid-push-5,
	.hpf-grid-push-5:first-child,
	.hpf-grid-push-6,
	.hpf-grid-push-6:first-child,
	.hpf-grid-push-7,
	.hpf-grid-push-7:first-child,
	.hpf-grid-push-8,
	.hpf-grid-push-8:first-child,
	.hpf-grid-push-9,
	.hpf-grid-push-9:first-child,
	.hpf-grid-push-10,
	.hpf-grid-push-10:first-child,
	.hpf-grid-push-11,
	.hpf-grid-push-11:first-child,
	.hpf-grid-push-12,
	.hpf-grid-push-12:first-child,
	.hpf-grid-push-13,
	.hpf-grid-push-13:first-child,
	.hpf-grid-push-14,
	.hpf-grid-push-14:first-child,
	.hpf-grid-push-15,
	.hpf-grid-push-15:first-child,
	.hpf-grid-push-16,
	.hpf-grid-push-16:first-child,
	.hpf-grid-push-17,
	.hpf-grid-push-17:first-child,
	.hpf-grid-push-18,
	.hpf-grid-col {
		left: 0;
	}

	.hpf-grid-row {
		margin-top: 0;
	}

	.hpf-grid-col {
		clear: both;
		float: none;
		margin-left: 0;
		margin-top: 1.5em;
		width: 100%;
	}

	.hpf-grid-col:first-child {
		margin-top: 0;
	}

	.hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-aside-bg-container,
	.hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-aside-bg-container {
		&::after {
			content: none;
		}
	}

	// Needed for iframes on iOS
	.overflowScroll {
		overflow: scroll;
	}

	// These need to be at least 16px in order to prevent automatic zooming in iOS
	select,
	textarea,
	input {
		font-size: 16px !important;
	}

	/* Content rotator */
	.hpf-zone-hero {
		background-color: #304b5e;
		background-image: none;
		margin-left: -15px;
		margin-right: -15px;

		div {
			display: block;
		}

		.display-none {
			display: none;
		}

		.hpf-bannerRotator {
			.hpf-slide {
				height: auto;
			}

			.hpf-mediaItem {
				padding: 18px 15px 0;

				&.hpf-mediaItem-right {
					.hpf-mediaItem-media {
						float: none;
					}
				}

				.hpf-mediaItem-media {
					margin: 0 0 25px;
					width: 100%;
				}
			}

			.hpf-mediaItem-body {
				float: none;
				margin: 0;
				max-height: none;
				padding: 0;
				width: 100%;

				.hpf-mediaItem-content {
					max-height: none;

					h2 {
						margin-bottom: 5px;
						max-height: none;
					}

					> p {
						font-size: $font-size-6;
						margin: 0;
					}
				}

				a.hpf-button {
					margin: 10px 0 0;
				}

				h2 {
					font-size: $font-size-3;
				}
			}
		}

		.flex-direction-nav {
			display: none;
		}

		.hpf-controls {
			margin: 0 auto;
			padding-bottom: 20px;
			position: static;
			width: 59px;
		}

		.flex-control-nav li {
			margin: 0 10px 0 0;

			&:last-child {
				margin-right: 0;
			}
		}

		.flex-control-paging li a {
			height: 13px;
			margin-top: 14px;
			width: 13px;
		}
	}
	/*********************************************************/

	/* News roll-up */
	h2.ms-webpart-titleText.ms-webpart-titleText {
		margin-bottom: 6px;
	}

	.hpf-largeRollup {
		.hpf-largeRollup-image,
		.hpf-largeRollup-content {
			float: none;
			margin: 0;
			width: 100%;
		}

		.hpf-largeRollup-image {
			margin-bottom: 12px;
		}

		.hpf-largeRollup-content {
			h3 {
				position: static;
			}
		}

		.hpf-date {
			margin-bottom: 13px;
			margin-top: 5px;
			text-transform: uppercase;
		}

		> li {
			&:last-child {
				margin-bottom: 0;

				&::after {
					height: 0;
				}
			}

			&.hpf-featureNewsItem {
				background-color: $light-blue-grey;
				float: none;
				width: 100%;

				> * {
					padding-left: 10px;
					padding-right: 10px;

					&:first-child,
					&:last-child {
						padding-top: 15px;
					}
				}
			}

			&.hpf-nonFeatureNewsItem {
				margin-left: 0;
			}
		}
	}

	/*********************************************************/

	#sideNavBox {
		display: none;
	}

	.hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-grid-row,
	.hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-grid-row {
		background-image: none;
	}

	.hpf-publishing {
		.hpf-zone-panel.hpf-aside-ontop {
			height: auto;
			position: static;
			width: auto;

			.hpf-grid-row,
			.hpf-grid-container {
				height: auto;
				padding-bottom: 0;
			}

			.hpf-grid-span-7 {
				box-shadow: none;
				padding: 45px 15px 0;
			}
		}
	}

	.hpf-zone-panel {
		border: 0;
		padding: 0 15px;

		&:last-child {
			.ms-webpartzone-cell {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.hpf-page-container {
		.hpf-zone-title {
			height: auto;
			max-height: 125px;
			padding: 24px 15px 23px;

			.hpf-page-title-inner {
				max-height: 125px;
				overflow: hidden;

				h1 {
					font-size: $font-size-3;
					height: auto;
					line-height: 1.2;
					max-height: 125px;
				}
			}
		}
	}

	.hpf-hasWebPartLogo {
		.ms-wpContentDivSpace {
			padding-left: 0;
		}

		h2 {
			&.ms-webpart-titleText {
				display: inline-block;
				padding-left: 0;

				img {
					display: block;
					left: auto;
					right: -52px;
					top: 0;
				}
			}
		}
	}

	.hpf-arrowListOnDark {
		li.hpf-borders {
			border: 0;

			&::before,
			&::after {
				border-top: 0;
			}
		}
	}

	.hpf-twoColumnRollup {
		li {
			float: none;
			width: 100%;

			&.hpf-rightColumn {
				margin-left: 0;
			}
		}
	}

	.ms-webpart-titleText.ms-webpart-titleText,
	.ms-webpart-titleText.ms-webpart-titleText a {
		font-size: $font-size-2;
		margin-bottom: 8px;
	}

	.ms-webpart-chrome-title {
		margin: 0;
	}

	.hpf-primaryContentRollup {
		li {
			h3 {
				margin-bottom: 15px;
			}
		}
	}

	.hpf-twitterList {
		p {
			margin-bottom: 5px;
		}
	}

	.hpf-ankle {
		h2 {
			font-size: $font-size-4;
			padding-left: 30px;
			padding-right: 30px;
		}

		input {
			font-size: 13px;
			padding: 6px 7px;
		}
	}

	.ms-rtestate-field img {
		border: 0 !important;
		box-shadow: none;
		display: block;
		padding: 0;
	}

	div.ms-rteElement-rightColumnLargeMargin {
		float: none;
		margin: 0;
	}


	/*#region $IMAGES - Image styles/positions in Image Styles/Position dropdowns (starting at -6 after SP OOB entries)*/
	.ms-rtePosition-6,
	.ms-rtestate-field img.ms-rtePosition-6,
	.ms-rtestate-field p img.ms-rtePosition-6,
	.ms-rtePosition-7,
	.ms-rtestate-field img.ms-rtePosition-7,
	.ms-rtestate-field p img.ms-rtePosition-7,
	.ms-rtePosition-8,
	.ms-rtestate-field img.ms-rtePosition-8,
	.ms-rtestate-field p img.ms-rtePosition-8,
	.ms-rtePosition-9,
	.ms-rtestate-field img.ms-rtePosition-9,
	.ms-rtestate-field p img.ms-rtePosition-9,
	.ms-rtePosition-10,
	.ms-rtestate-field img.ms-rtePosition-10,
	.ms-rtestate-field p img.ms-rtePosition-10,
	.ms-rtePosition-11,
	.ms-rtestate-field img.ms-rtePosition-11,
	.ms-rtestate-field p img.ms-rtePosition-11 {
		float: none;
	}
	/*#endregion*/

	.ms-floatLeft,
	.ms-floatRight {
		float: none;
	}

	/* Search results */
	.ms-searchCenter-result-main {
		float: none;
	}

	.ms-srch-item-body {
		display: block;
	}

	.ms-srch-item,
	.ms-srch-bestBetItem,
	.ms-srch-item-path {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
	/* =============================== */

	/* Search refiners */
	.hpf-refiners {
		background-color: rgba(0, 0, 0, .8);
		bottom: 0;
		display: none;
		height: 100%;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;

		.hpf-refiners__innerContainer {
			background-color: $white;
			border-radius: 2px;
			margin-top: 40%;
			max-height: 60%;
			overflow: auto;
			padding: 20px;
			position: relative;
		}

		&.active {
			display: block;

			.hpf-refiners__btnClose {
				display: block;
			}
		}

		~ div {
			margin-top: 0;
		}
	}

	.ms-ref-refiner {
		box-sizing: border-box;
		float: left;
		padding: 0 0 0 15px;
		width: 33.3333%;

		&:first-child {
			padding-left: 0;
		}

		&[refinername*='RefinementYear'] {
			width: 18%;
		}

		&[refinername*='RefinementMonth'] {
			width: 25%;
		}

		&[refinername*='FNHASharingCat'],
		&[refinername*='FNHAHBNewsCat'] {
			width: 57%;
		}

		#Container {
			padding-bottom: 0;
		}
	}
	/* =============================== */


	/* Mobile Buttons */
	.hpf-button,
	.hpf-button-alt1,
	.hpf-button-small,
	.hpf-button-big,
	.hpf-button-big-alt1,
	.hpf-button-download,
	.hpf-button-share,
	.ms-rteElement-button,
	.ms-rteElement-button-download {
		border-top: 0;
		font-size: 12px;
		padding: 6px 11px;
	}

	.hpf-button-download,
	.ms-rteElement-button-download {
		padding: 3px 11px 3px 20px;
	}

	.hpf-button {
		&--refiner {
			@include display-inline-block;
			background: transparent;
			border: 1px solid $white;
			box-shadow: none;
			cursor: pointer;
			position: absolute;
			right: 10px;
			top: 86px;

			&:hover,
			&:active {
				background: transparent;
				color: $white;
				text-decoration: none;
			}

			&.active {
				background-color: $light-blue;
			}
		}
	}

	.hpf-button-share {
		padding-left: 40px;

		&::after {
			top: 5px;
		}
	}

	.hpf-share__button {
		padding: 5px;

		span {
			background-size: 75px 15px;
			height: 16px;
			width: 18px;
		}

		&--facebook {
			span {
				background-position: 5px 1px;
			}
		}

		&--twitter {
			span {
				background-position: -28px 1px;
			}
		}

		&--linkedin {
			span {
				background-position: -57px 1px;
			}
		}
	}
	/* =============================== */


	/* Mobile Locations Page*/
	.locations {
		display: none;
	}

	.mobileLocationsSelector {
		display: block;

		select {
			max-width: 100%;
			padding: 8px 10px;
		}

		option {
			color: $orange;
			font-size: $font-size-5 + 1;
			padding: 8px 10px;

			&[disabled] {
				color: $black;
			}
		}
	}
	/* =============================== */

	/* Mobile Employee Directory */
	.hpf-mobileEmployeeDirSelect {
		display: block;
		margin-bottom: 20px;
		max-width: 100%;
	}
	/* =============================== */
}
/********************** 767px **********************/


// Targeting iPad 1 as it has some problems rendering the content rotator and megamenu
@media screen and (device-width: 768px) and (device-height: 1024px) and (device-pixel-ratio: 1) {
	.hpf-bannerRotator {
		/* Fixes the issue of megamenu being overlayed on top of
			content rotator when brought on screen */
		* {
			max-height: 458px;
			z-index: 0 !important;
		}

		/* iPad 1 stretches the image out to >2000px for some reason.
			Putting this here as quick fix */
		.hpf-mediaItem-media {
			img {
				max-height: 458px;
			}
		}
	}
}

// On screens <1600px the search item hover preview doesn't fit so we hide it
@media (max-width: 1599px) {
	.ms-srch-hover-outerContainer {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.ms-ref-refiner {
		.ms-ref-filterSel,
		.ms-ref-name {
			background-image: none;
			padding-left: 0;
		}
	}
}
