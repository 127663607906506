﻿@import 'variables';
@import 'mixins';

@mixin clearfix {
	overflow: auto;

	&::after {
		clear: both;
		content: '';
		display: table;
	}
}

@mixin padding-bottom-trick($padding-bottom) {
	height: 0;
	overflow: hidden;
	padding-bottom: $padding-bottom;
	position: relative;
}

@mixin content {
	content: '';
	display: block;
	position: absolute;
}

.hpf-publishing-landing--full-span {
	font-family: 'Barlow', sans-serif;

	#sideNavBox {
		display: none !important;
	}

	.hpf-page-container .hpf-page-title .hpf-page-title-inner h1 {
		align-content: center;
		align-items: center;
		display: flex;
		justify-content: center;
		text-align: center;
	}

	img {
		background: none;
		background-color: transparent;
		border: 0 !important;
		box-shadow: none !important;
		display: block;
		margin: 0;
		padding: 0;
	}

	.image-panel-full-span {
		margin-left: calc(-50vw + 50%);
		width: 100vw;

		& > img {
			display: block;
			margin: 0 !important;
			max-width: 100%;
			padding: 0 !important;
			width: 100%;
		}
	}

	.fnha-cannabis-menu {
		align-items: center;
		display: flex;
		flex-direction: column;
		font-size: 21px;
		justify-content: center;
		margin: 0 auto;
		text-align: center;

		&,
		* {
			line-height: 1;
		}

		&__item {
			display: flex;
			margin-bottom: 24px;

			&-link {
				border-radius: 4px;
				box-sizing: border-box;
				color: #fff;
				display: block; // Need this for IE
				font-weight: $font-weight-semi-bold;
				min-width: 267px;
				padding: 13px 0;
				position: relative;
				text-decoration: none;
				text-transform: capitalize;

				&--active {
					&::after {
						bottom: -10px;
						content: '';
						height: 2px;
						left: 15%;
						position: absolute;
						width: 70%;
					}

					&#get-informed {
						&::after {
							background-color: $teal;
						}

						&:hover::after {
							background-color: lighten($teal, 5%);
						}
					}

					&#get-support {
						&::after {
							background-color: $orange;
						}

						&:hover::after {
							background-color: lighten($orange, 5%);
						}
					}

					&#support-others {
						&::after {
							background-color: $red;
						}

						&:hover::after {
							background-color: lighten($red, 5%);
						}
					}
				}

				&#home {
					background-color: $purple;

					&:hover {
						background-color: lighten($purple, 5%);
					}
				}

				&#get-informed {
					background-color: $teal;

					&:hover {
						background-color: lighten($teal, 5%);
					}
				}

				&#get-support {
					background-color: $orange;

					&:hover {
						background-color: lighten($orange, 5%);
					}
				}

				&#support-others {
					background-color: $red;

					&:hover {
						background-color: lighten($red, 5%);
					}
				}
			}
		}
	}

	.fnha-cannabis-wide-content {
		font-size: 20px;
		line-height: 1.8;
		text-align: center;
	}

	.fnha-cannabis-narrow-content {
		font-size: 20px;
		line-height: 1.8;
		margin: 0 auto;
		max-width: 85%;
		text-align: center;
	}

	.fnha-cannabis-button {
		background-color: transparent;
		border: 2px solid #2a2931;
		border-radius: 5px;
		box-shadow: none;
		color: #2a2931;
		cursor: pointer;
		display: inline-block;
		font-size: 17px;
		font-weight: 600;
		line-height: 1;
		margin: 0;
		min-width: 0;
		padding: 15px 27px;
		text-align: center;
		text-decoration: none !important;
		text-transform: capitalize;
		transition: all .1s ease-in;
		vertical-align: middle;

		&:hover,
		&:focus,
		&:active {
			background-color: #2a2931;
			color: #fff;
		}

		&__container {
			text-align: center;
		}
	}

	.fnha-cannabis-tiles {
		&__list-item {
			width: 100%;
		}

		&__tile {
			margin: 0;
			margin-bottom: 16px;
			position: relative;
			width: 100%;

			a:hover {
				&::after {
					@include content;
					@include position-absolute-all;
					background-color: rgba(0, 0, 0, .5);
					height: 100%;
					width: 100%;
				}
			}

			img {
				margin: 0 !important;
				width: 100%;
			}

			&-content {
				position: relative;
			}

			&-text {
				align-content: center;
				align-items: center;
				border: 2px solid rgba(255, 255, 255, .5);
				box-sizing: border-box;
				color: #fff;
				display: flex;
				font-size: 26px;
				font-weight: $font-weight-bold;
				height: #{'calc(100% - 80px)'};
				justify-content: center;
				left: 50%;
				line-height: 1;
				padding: 20px;
				position: absolute;
				text-align: center;
				text-transform: uppercase;
				top: 50%;
				transform: translate(-50%, -50%);
				width: #{'calc(100% - 80px)'};
				z-index: 1;
			}
		}
	}
}

@media (min-width: 768px) {
	.hpf-publishing-landing--full-span {
		.fnha-cannabis-menu {
			flex-direction: row;

			&__item {
				margin-bottom: 0;
				margin-right: 35px;

				&:last-child {
					margin-right: 0;
				}

				&-link {
					min-width: 210px;
				}
			}
		}
	}

	.hpf-publishing-landing--full-span:not(.hpf-editMode) {
		.fnha-cannabis-tiles {
			&--medium {
				@include padding-bottom-trick(49.01960784313725%);
			}

			&--medium-tall {
				@include padding-bottom-trick(57.74647887323944%);
			}

			&--narrow {
				@include padding-bottom-trick(23.52941176470588%);
			}

			&--tall {
				@include padding-bottom-trick(83.333333%);
			}

			&__list-item {
				position: absolute;
				width: 100%;

				&--top-right {
					right: 0;
					top: 0;
				}

				&--top-left {
					left: 0;
					top: 0;
				}

				&--bottom-right {
					bottom: 0;
					right: 0;
				}

				&--middle-right {
					right: 0;
					top: 30.65%;
				}

				&--middle-left {
					left: 0;
					top: 30.65%;
				}

				&--middle-bottom-right {
					right: 0;
					top: 41.25%;
				}

				&--bottom-left {
					bottom: 0;
					left: 0;
				}

				&--half {
					width: 49%;
				}
			}

			&__tile {
				margin: 0;
				position: relative;
				width: 100%;

				&-content {
					left: 0;
					position: absolute;
					top: 0;
				}

				&--medium {
					@include padding-bottom-trick(100%);
				}

				&--small {
					@include padding-bottom-trick(47.84688995215311%);
				}

				&--large {
					@include padding-bottom-trick(32.23529411764706%);
				}
			}
		}
	}
}
