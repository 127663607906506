﻿
//Grid CSS - based on 24 Column Responsive Grid System (http://responsive.gs)

//Set all elements box-sizing to border-box so that you can add padding and border to a grid column without breaking the layout.
//If you need support for IE7 and lower use polyfill: https://github.com/Schepp/box-sizing-polyfill

.hpf-grid-container,
.hpf-grid-row,
.hpf-grid-col {
	box-sizing: border-box;
	position: relative;
}

.hpf-grid-container::before,
.hpf-grid-container::after,
.hpf-grid-row::before,
.hpf-grid-row::after,
.hpf-grid-col::before,
.hpf-grid-col::after,
.hpf-grid-clr::before,
.hpf-grid-clr::after,
.hpf-grid-group::before,
.hpf-grid-group::after {
	content: ' ';
	display: table;
}

.hpf-grid-container::after,
.hpf-grid-row::after,
.hpf-grid-col::after,
.hpf-grid-clr::after,
.hpf-grid-group::after {
	clear: both;
}

.hpf-grid-container {
	margin: 0 auto;
	max-width: 1024px;
}

/* Default row styles (set according to preference) */
.hpf-grid-row {
	margin-top: 1.5em;
}

.hpf-grid-row,
.hpf-grid-group {
	margin-left: -15px;
	margin-right: -15px;
}

/* Default column styles */
.hpf-grid-col {
	display: block;
	float: left;
	padding-left: 15px;
	padding-right: 15px;
}

/* Reset margins */
.hpf-grid-row:first-child {
	margin-top: 0;
}


/*#region $DEFAULT COLUMN WIDTHS */

.hpf-grid-span-1 {
	width: 4.16667%;
}

.hpf-grid-span-2 {
	width: 8.33333%;
}

.hpf-grid-span-3 {
	width: 12.5%;
}

.hpf-grid-span-4 {
	width: 16.66667%;
}

.hpf-grid-span-5 {
	width: 20.83333%;
}

.hpf-grid-span-6 {
	width: 25%;
}

.hpf-grid-span-7 {
	width: 29.16667%;
}

.hpf-grid-span-8 {
	width: 33.33333%;
}

.hpf-grid-span-9 {
	width: 37.5%;
}

.hpf-grid-span-10 {
	width: 41.66667%;
}

.hpf-grid-span-11 {
	width: 45.83333%;
}

.hpf-grid-span-12 {
	width: 50%;
}

.hpf-grid-span-13 {
	width: 54.16667%;
}

.hpf-grid-span-14 {
	width: 58.33333%;
}

.hpf-grid-span-15 {
	width: 62.5%;
}

.hpf-grid-span-16 {
	width: 66.66667%;
}

.hpf-grid-span-17 {
	width: 70.83333%;
}

.hpf-grid-span-18 {
	width: 75%;
}

.hpf-grid-span-19 {
	width: 79.16667%;
}

.hpf-grid-span-20 {
	width: 83.33333%;
}

.hpf-grid-span-21 {
	width: 87.5%;
}

.hpf-grid-span-22 {
	width: 91.66667%;
}

.hpf-grid-span-23 {
	width: 95.83333%;
}

.hpf-grid-span-24 {
	width: 100%;
}

.hpf-grid-push-1 {
	left: 4.16667%;
}

.hpf-grid-push-2 {
	left: 8.33333%;
}

.hpf-grid-push-3 {
	left: 12.5%;
}

.hpf-grid-push-4 {
	left: 16.66667%;
}

.hpf-grid-push-5 {
	left: 20.83333%;
}

.hpf-grid-push-6 {
	left: 25%;
}

.hpf-grid-push-7 {
	left: 29.16667%;
}

.hpf-grid-push-8 {
	left: 33.33333%;
}

.hpf-grid-push-9 {
	left: 37.5%;
}

.hpf-grid-push-10 {
	left: 41.66667%;
}

.hpf-grid-push-11 {
	left: 45.83333%;
}

.hpf-grid-push-12 {
	left: 50%;
}

.hpf-grid-push-13 {
	left: 54.16667%;
}

.hpf-grid-push-14 {
	left: 58.33333%;
}

.hpf-grid-push-15 {
	left: 62.5%;
}

.hpf-grid-push-16 {
	left: 66.66667%;
}

.hpf-grid-push-17 {
	left: 70.83333%;
}

.hpf-grid-push-18 {
	left: 75%;
}

.hpf-grid-push-19 {
	left: 79.16667%;
}

.hpf-grid-push-20 {
	left: 83.33333%;
}

.hpf-grid-push-21 {
	left: 87.5%;
}

.hpf-grid-push-22 {
	left: 91.66667%;
}

.hpf-grid-push-23 {
	left: 95.83333%;
}

.hpf-grid-pull-1 {
	left: -4.16667%;
}

.hpf-grid-pull-2 {
	left: -8.33333%;
}

.hpf-grid-pull-3 {
	left: -12.5%;
}

.hpf-grid-pull-4 {
	left: -16.66667%;
}

.hpf-grid-pull-5 {
	left: -20.83333%;
}

.hpf-grid-pull-6 {
	left: -25%;
}

.hpf-grid-pull-7 {
	left: -29.16667%;
}

.hpf-grid-pull-8 {
	left: -33.33333%;
}

.hpf-grid-pull-9 {
	left: -37.5%;
}

.hpf-grid-pull-10 {
	left: -41.66667%;
}

.hpf-grid-pull-11 {
	left: -45.83333%;
}

.hpf-grid-pull-12 {
	left: -50%;
}

.hpf-grid-pull-13 {
	left: -54.16667%;
}

.hpf-grid-pull-14 {
	left: -58.33333%;
}

.hpf-grid-pull-15 {
	left: -62.5%;
}

.hpf-grid-pull-16 {
	left: -66.66667%;
}

.hpf-grid-pull-17 {
	left: -70.83333%;
}

.hpf-grid-pull-18 {
	left: -75%;
}

.hpf-grid-pull-19 {
	left: -79.16667%;
}

.hpf-grid-pull-20 {
	left: -83.33333%;
}

.hpf-grid-pull-21 {
	left: -87.5%;
}

.hpf-grid-pull-22 {
	left: -91.66667%;
}

.hpf-grid-pull-23 {
	left: -95.83333%;
}

.hpf-grid-offset-1 {
	margin-left: 4.16667%;
}

.hpf-grid-offset-2 {
	margin-left: 8.33333%;
}

.hpf-grid-offset-3 {
	margin-left: 12.5%;
}

.hpf-grid-offset-4 {
	margin-left: 16.66667%;
}

.hpf-grid-offset-5 {
	margin-left: 20.83333%;
}

.hpf-grid-offset-6 {
	margin-left: 25%;
}

.hpf-grid-offset-7 {
	margin-left: 29.16667%;
}

.hpf-grid-offset-8 {
	margin-left: 33.33333%;
}

.hpf-grid-offset-9 {
	margin-left: 37.5%;
}

.hpf-grid-offset-10 {
	margin-left: 41.66667%;
}

.hpf-grid-offset-11 {
	margin-left: 45.83333%;
}

.hpf-grid-offset-12 {
	margin-left: 50%;
}

.hpf-grid-offset-13 {
	margin-left: 54.16667%;
}

.hpf-grid-offset-14 {
	margin-left: 58.33333%;
}

.hpf-grid-offset-15 {
	margin-left: 62.5%;
}

.hpf-grid-offset-16 {
	margin-left: 66.66667%;
}

.hpf-grid-offset-17 {
	margin-left: 70.83333%;
}

.hpf-grid-offset-18 {
	margin-left: 75%;
}

.hpf-grid-offset-19 {
	margin-left: 79.16667%;
}

.hpf-grid-offset-20 {
	margin-left: 83.33333%;
}

.hpf-grid-offset-21 {
	margin-left: 87.5%;
}

.hpf-grid-offset-22 {
	margin-left: 91.66667%;
}

.hpf-grid-offset-23 {
	margin-left: 95.83333%;
}

/*#endregion*/
