﻿//print.css contains print specific styles


@media print {
	.ms-mpSearchBox,
	.hpf-footer-contactUs,
	.hpf-ankle {
		display: none;
	}


	/*#region $HEADER */

	.ms-siteicon-img {
		max-height: none;
		max-width: none;
	}

	/*#endregion*/
}
