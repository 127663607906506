// reference/variables/_typography.scss

@import 'colors';

/// @nuclide Sans Serif Font Stack
/// @section Variables > Typography, Font Families
$font-family-primary:		'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif !default;

/// @nuclide Serif Font Stack
/// @section Variables > Typography, Font Families
$font-family-secondary:		'Aleo', Palatino, Georgia, serif !default;

/// @nuclide Monospace Font Stack
/// @section Variables > Typography, Font Families
$font-family-monospace:		Consolas, monospace !default;

/// @nuclide Body Text Font Stack
/// @section Variables > Typography, Font Families
$font-family-body:          $font-family-primary !default;

/// @nuclide Title Text Font Stack
/// @section Variables > Typography, Font Families
$font-family-title:         $font-family-secondary !default;


/// @nuclide Smaller Font Size
/// @section Variables > Typography, Font Sizes
$font-size-smaller: 12px !default;

/// @nuclide Small Font Size
/// @section Variables > Typography, Font Sizes
$font-size-small:   14px !default;

/// @nuclide Medium Font Size
/// @section Variables > Typography, Font Sizes
$font-size-medium:  16px !default;

/// @nuclide Large Font Size
/// @section Variables > Typography, Font Sizes
$font-size-large:   21px !default;

/// @nuclide Larger Font Size
/// @section Variables > Typography, Font Sizes
$font-size-larger:  28px !default;

/// @nuclide Mobile Input Font Size
/// @section Variables > Typography, Font Sizes
$font-size-mobile-inputs: 16px !default;  // Ensures no zooming on focus


/// @nuclide Font Style Normal
/// @section Variables > Typography, Font Style
$font-style-normal:     normal !default;

/// @nuclide Font Style Italic
/// @section Variables > Typography, Font Style
$font-style-italic:     italic !default;


/// @nuclide Light Weight
/// @section Variables > Typography, Font Weight
$font-weight-light:     100 !default;

/// @nuclide Normal Weight
/// @section Variables > Typography, Font Weight
$font-weight-normal:    400 !default;

/// @nuclide Medium Weight
/// @section Variables > Typography, Font Weight
$font-weight-medium:    600 !default;

/// @nuclide Bold Weight
/// @section Variables > Typography, Font Weight
$font-weight-bold:      700 !default;


/// @nuclide Smaller Line Height
/// @section Variables > Typography, Line Height
$line-height-smaller:  1.125 !default;


/// @nuclide Small Line Height
/// @section Variables > Typography, Line Height
$line-height-small:  1.25 !default;


/// @nuclide Medium Line Height
/// @section Variables > Typography, Line Height
$line-height-medium:   1.5 !default;


/// @nuclide Body Color
/// @section Variables > Typography, Color
$body-color: $grey-dark !default;


/// @nuclide Paragraph font size on mobile
/// @section Variables > Typography, Paragraph styles
$paragraph-font-size-up-to-tablet: $font-size-small !default;

/// @nuclide Paragraph font size on tablet and up
/// @section Variables > Typography, Paragraph styles
$paragraph-font-size-tablet-up: $font-size-medium !default;

/// @nuclide Paragraph line-height
/// @section Variables > Typography, Paragraph styles
$paragraph-line-height: $line-height-medium !default;

/// @nuclide Sidebar paragraph font size
/// @section Variables > Typography, Sidebar paragraph styles
$sidebar-paragraph-font-size: $font-size-small !default;

/// @nuclide Sidebar paragraph line-height
/// @section Variables > Typography, Sidebar paragraph styles
$sidebar-paragraph-line-height: $line-height-medium !default;


/// @nuclide Hyperlink Color
/// @section Variables > Typography, Color
$hyperlink-color: $accent-primary !default;
$hyperlink-hover-color: darken($hyperlink-color, 12.5%) !default;
$hyperlink-active-color: darken($hyperlink-hover-color, 12.5%) !default;

$hyperlink-underline: rgba($hyperlink-color, .3) !default;


$blockquote-border-left: 3px solid $accent-secondary !default;
$blockquote-color: $grey !default;
$blockquote-font: $font-style-normal $font-weight-light $font-size-large#{'/'}$line-height-medium $font-family-secondary !default;
$blockquote-line-height: $line-height-medium !default;
$blockquote-margin: sp(7) 0 sp(7) !default;
$blockquote-padding: 0 0 0 sp(2) !default;


$cite-color: $grey !default;
$cite-font: normal normal $font-weight-normal $font-size-medium $font-family-secondary !default;
$cite-margin: sp(2) 0 sp(2);


$caption-border-top: 2px solid $accent-secondary !default;
$caption-color: $grey !default;
$caption-font: $font-style-italic $font-weight-normal $font-size-smaller#{'/'}$line-height-medium $font-family-primary !default;
$caption-margin: sp(1) 0 0 0 !default;
$caption-padding: sp(.5) 0 0 0 !default;


// Headings
$subheading-color: $grey !default;
$subheading-font: $font-weight-light $font-size-larger#{'/'}$line-height-medium $font-family-secondary !default;
$subheading-margin: sp(4) 0 !default;

$headings-base-color: $grey-darker !default;
$headings-base-font-family: $font-family-secondary !default;
$headings-base-font-weight: $font-weight-normal !default;
$headings-base-text-transform: none !default;
$headings-base-line-height: $line-height-small !default;
$headings-base-margin: 1.5em 0 !default;

$headings-h1-color: $grey-darker !default;
$headings-h1-font: $font-weight-normal 51px#{'/'}$line-height-smaller $headings-base-font-family !default;
$headings-h1-margin: 0 0 sp(4) !default;

$headings-h2-color: $grey-darker !default;
$headings-h2-font: $font-weight-normal 38px#{'/'}$line-height-small $headings-base-font-family !default;
$headings-h2-margin: sp(6) 0 sp(3) !default;
$headings-h2-text-transform: none !default;

$headings-h3-color: $grey-darker !default;
$headings-h3-font: $font-weight-normal $font-size-larger#{'/'}$line-height-small $font-family-secondary !default;
$headings-h3-margin: sp(5) 0 sp(3) !default;
$headings-h3-text-transform: none !default;

$headings-h4-color: $grey-darker !default;
$headings-h4-font: $font-weight-normal $font-size-large#{'/'}$line-height-small $font-family-secondary !default;
$headings-h4-margin: sp(4) 0 sp(2) !default;
$headings-h4-text-transform: none !default;

$headings-h5-color: $grey-darker !default;
$headings-h5-font: $font-weight-bold $font-size-medium#{'/'}$line-height-small $font-family-secondary !default;
$headings-h5-margin: sp(4) 0 sp(2) !default;
$headings-h5-text-transform: none !default;

$headings-h6-color: $grey-darker !default;
$headings-h6-font: $font-weight-normal $font-size-medium#{'/'}$line-height-small $font-family-secondary !default;
$headings-h6-margin: sp(4) 0 sp(2) !default;
$headings-h6-text-transform: none !default;
