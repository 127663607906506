// reference/mixins/_color-states.scss


/// A helper mixin for setting properties on multiple element states (focus, hover, active, visited).
///
/// @mixin
/// @section Color Mixins
/// @param $property The CSS property to change across various states.
/// @param $base The base color to set the CSS property to.
/// @param $hover The hover color to set the CSS property to. Defaults to 12.5% darker than $base.
/// @param $active The active color to set the CSS property to. Defaults to 12.5% darker than $hover.
/// @param $visited The visited color to set the CSS property to. Defaults to the $base color.
/// @example
///  @include property-color-states('border-color', $grey-dark, $grey-dark, $grey-darker);
@mixin property-color-states($property, $base, $hover: darken($base, 12.5%), $active: darken($hover, 12.5%), $visited: $base) {
	@if $property == 'color' {
		&,
		&:visited {
			#{$property}: $base;
		}
	} @else {
		#{$property}: $base;
	}

	&:visited {
		#{$property}: $base;
	}

	&:focus,
	&:hover {
		#{$property}: $hover;
	}

	&:active {
		#{$property}: $active;
	}
}


/// A helper mixin for setting background colors on multiple element states (focus, hover, active).
///
/// @mixin
/// @section Color Mixins
/// @param $base The base color to set the background color to.
/// @param $hover The hover color to set the background color to. Defaults to 12.5% darker than $base.
/// @param $active The active color to set the background color to. Defaults to 12.5% darker than $hover.
/// @example
///  @include background-color-states($grey-dark, $grey-dark, $grey-darker);
@mixin background-color-states($base, $hover: darken($base, 12.5%), $active: darken($hover, 12.5%)) {
	@include property-color-states('background-color', $base, $hover, $active);
}


/// A helper mixin for setting font colors on multiple element states (focus, hover, active).
///
/// @mixin
/// @section Color Mixins
/// @param $base The base color to set the font color to.
/// @param $hover The hover color to set the font color to. Defaults to 12.5% darker than $base.
/// @param $active The active color to set the font color to. Defaults to 12.5% darker than $hover.
/// @example
///  @include color-states($grey-dark, $grey-dark, $grey-darker);s
@mixin color-states($base, $hover: darken($base, 12.5%), $active: darken($hover, 12.5%)) {
	@include property-color-states('color', $base, $hover, $active, $base);
}
