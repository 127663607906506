// reference/mixins/_accordion.scss

@import '../variables/animation';
@import '../variables/colors';
@import 'color-states';
@import 'helpers';
@import 'images';
@import '../patterns/social';
@import '../patterns/tooltip';

@mixin accordion {
	background-color: $accordion-background-color;
	margin: sp(2) 0;
	padding: 0 sp(2) !important;
}

/// Styles for the accordion title in it's collapsed used in the page content
///
/// @mixin
/// @section Interface elements mixins
/// @example
/// .go-accordion {
///		@include accordion-title-collapsed;
///  }
@mixin accordion-title-collapsed {
	background-color: $accordion-title-background-color;
	border: $accordion-title-border;
	border-radius: $accordion-border-radius;
	color: $accordion-title-color;
	cursor: pointer;
	display: block;
	font: $accordion-title-font;
	margin: 0 sp(-2) sp(1.5);
	padding: sp(2) sp(5) sp(2) sp(2);
	position: relative;
	text-transform: none;

	&::after {
		color: $accordion-icon-color;
		content: '\002b';
		font-size: 30px;
		font-weight: $font-weight-normal;
		position: absolute;
		right: sp(2);
		top: 5px;
	}
}


/// Styles for the accordion title in it's expanded used in the page content
///
/// @mixin
/// @section Interface elements mixins
/// @example
/// .go-accordion--expanded {
///		@include accordion-title-expanded;
///  }
@mixin accordion-title-expanded {
	background-color: $accordion-title-expanded-background-color;
	border-radius: $accordion-border-radius $accordion-border-radius 0 0;
	color: $accordion-title-expanded-color;

	&::after {
		color: $accordion-icon-expanded-color;
		content: '\2013';
	}
}
