// reference/variables/_z-index.scss

/// @nuclide Regular
/// @section Variables > Z-Index
$z-index-regular:   1000 !default;

/// @nuclide Flyout
/// @section Variables > Z-Index
$z-index-flyout:    2000 !default;

/// @nuclide Overlay
/// @section Variables > Z-Index
$z-index-overlay:   3000 !default;

/// @nuclide Refiner
/// @section Variables > Z-Index
$z-index-refiner:   4000 !default;

/// @nuclide Modal
/// @section Variables > Z-Index
$z-index-modal:     5000 !default;
