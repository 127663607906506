// reference/variables/_colors.scss

/// @color Grey White
/// @section Grey
$grey-white: #eff3f5 !default;

/// @color Grey Lighter
/// @section Grey
$grey-lighter: #d3dad3 !default;

/// @color Grey Light
/// @section Grey
$grey-light: #b0b7bc !default;

/// @color Grey
/// @section Grey
$grey: #627a87 !default;

/// @color Grey
/// @section Grey
$grey-dark: #455560 !default;

/// @color Grey Dark
/// @section Grey
$grey-darker: #293339 !default;

/// @color Grey Darker
/// @section Grey
$grey-black: #131a1e !default;


/// @color Accent Primary
/// @section Accents
$accent-primary: #2170bf !default;

/// @color Accent Primary Light
/// @section Accents
$accent-primary-light: #28abe3 !default;

/// @color Acent Primary Dark
/// @section Accents
$accent-primary-dark: #1c60a5 !default;

/// @color Accent Secondary
/// @section Accents
$accent-secondary: #20e3a0 !default;

/// @color Accent Secondary Light
/// @section Accents
$accent-secondary-light: #defbf1 !default;

/// @color Accent Secondary Dark
/// @section Accents
$accent-secondary-dark: #17a273 !default;

/// @color Accent Tertiary
/// @section Accents
$accent-tertiary: #e8b71a !default;

/// @color Accent Light
/// @section Accents
$accent-light: #d626a1 !default;


/// @color State Warning
/// @section States
$state-warning: #e8b71a !default;

/// @color State Fatal
/// @section States
$state-fatal: #a71830 !default;

/// @color State Success
/// @section States
$state-success: #20e3a0 !default;


/// @color White
/// @section White
$white:				#fff !default;

/// @color White (75%)
/// @section White
$white-75:          rgba(255, 255, 255, .75) !default;

/// @color White (50%)
/// @section White
$white-50:          rgba(255, 255, 255, .5) !default;

/// @color White (25%)
/// @section White
$white-25:          rgba(255, 255, 255, .25) !default;

/// @color White (15%)
/// @section White
$white-15:          rgba(255, 255, 255, .15) !default;

/// @color Black
/// @section Black
$black:            #000 !default;

/// @color Black (75%)
/// @section Black
$black-75:          rgba(0, 0, 0, .75) !default;

/// @color Black (50%)
/// @section Black
$black-50:          rgba(0, 0, 0, .5) !default;

/// @color Black (25%)
/// @section Black
$black-25:          rgba(0, 0, 0, .25) !default;

/// @color Black (15%)
/// @section Black
$black-15:          rgba(0, 0, 0, .15) !default;
