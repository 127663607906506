﻿//style.css contains the custom CSS for fnha.com
// Authors: John Allan


@import 'variables';
@import 'mixins';

/*#region $BODY */
body {
	font-family: $font-family-sans;
	font-weight: $font-weight-normal;
}

.hpf-page-container {
	background-color: $bg-white;
	border-bottom-color: #f8f8f9;
}

.no-generatedcontent {
	.hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-aside-bg-container,
	.hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-aside-bg-container {
		background-image: url('#{$brand-images}/aside-shadow-bkd.png');
		background-position: 88% 0;
		background-repeat: repeat-y;

		// No need for a bg image in nested grids
		.hpf-grid-row {
			background-image: none;
		}
	}
}

.hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-aside-bg-container,
.hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-aside-bg-container {
	position: relative;

	&::after {
		background-image: url('#{$brand-images}/aside-shadow-bkd.png');
		background-repeat: repeat-y;
		content: '';
		display: block;
		height: 100%;
		left: 80.1666%;
		position: absolute;
		top: 0;
		width: 109px;
		z-index: 1;
	}

	.hpf-aside-bg-container {
		&::after {
			content: none;
		}
	}
}

.cssgradients .hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-grid-row::before,
.cssgradients .hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-grid-row::before {
	/* http://www.colorzilla.com/gradient-editor/#fafafa+0,fafafa+75&0+0,1+75;Custom */
	background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgba(250, 250, 250, 1) 75%);
	bottom: 0;
	content: ' ';
	height: 100px;
	left: 88%;
	margin-left: -100px;
	position: absolute;
	width: 50px;
	z-index: 2;
}

.cssgradients .hpf-editMode.hpf-publishing-content-aside-1 .hpf-zone-panel .hpf-grid-row::before,
.cssgradients .hpf-editMode.hpf-publishing-content-nav-aside-1 .hpf-zone-panel .hpf-grid-row::before,
.cssgradients .hpf-publishing-content-aside-1 .hpf-zone-hero .hpf-grid-row::before,
.cssgradients .hpf-publishing-content-nav-aside-1 .hpf-zone-hero .hpf-grid-row::before {
	display: none;
}
/*#endregion*/


/*#region $GLOBALSTYLES */

//This selector will hide all the text on the page until google loads in the font
/*.wf-loading body {
	h1, h2, h3, h4, p, li, a, span{
		display: none;
	}
}*/

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-sans;
}

strong {
	font-weight: $font-weight-bold;
}


a,
a:visited,
.ms-link:visited {
	color: $orange;
}

p {
	line-height: 1.4;
}

.ms-srch-sbLarge input {
	font-size: $font-size-6;
}

hr {
	@include item-dividers(0);
	border-top: 1px solid;
	margin: 25px 0;
}

.hpf-seeMore,
.ms-rteElement-seeMore {
	color: $green;
	font-style: italic;

	&:link,
	&:visited {
		color: $green;
	}
}

.hpf-pdfLink,
.ms-rteElement-pdfLink {
	@include icon-sprite(10);
	padding-left: 25px;
}

.hpf-seeMore {
	font-size: $font-size-4;
}

.hpf-wellnessResourcesItem {
	@include icon-sprite(15);
}

.hpf-filetype-pdf {
	@include icon-sprite(10);
}

.hpf-filetype-html,
.hpf-filetype-htm,
.hpf-filetype-xml,
.hpf-filetype-aspx,
.hpf-filetype-asp,
.hpf-filetype-ascx {
	@include icon-sprite(12);
}

.hpf-filetype-doc,
.hpf-filetype-docx,
.hpf-filetype-docm,
.hpf-filetype-dot,
.hpf-filetype-dotx {
	@include icon-sprite(13);
}

.hpf-filetype-xls,
.hpf-filetype-xlsx,
.hpf-filetype-ods,
.hpf-filetype-xlsm,
.hpf-filetype-xlsb {
	@include icon-sprite(14);
}


/*#endregion*/


/*#region $MEDIAITEM */

.hpf-mediaItem,
.hpf-mediaItem-body {
	overflow: hidden;
	word-wrap: break-word;
}

.hpf-mediaItem .hpf-mediaItem-media img,
.hpf-mediaItem img.hpf-mediaItem-media {
	display: block;
}

.hpf-mediaItem .hpf-mediaItem-media {
	float: right;
	margin-right: 10px;
}

.hpf-mediaItem.hpf-mediaItem-right .hpf-mediaItem-media {
	margin-left: 10px;
	margin-right: 0;
}

/*#endregion*/


/*#region $PANELS */

.hpf-zone-panel {
	@include linear-gradient($bg-white, #e0e1e3, $bg-white);
	background-repeat: repeat no-repeat;
	background-size: auto 229px;
	border-top-color: $lighter-grey;
}

.hpf-zone-panel-alt {
	/* tslint:disable:max-line-length */
	background: url('/_layouts/15/Styles/Themable/FNHA.Internet.Website.Brand/images/zone-panel-alt-bkd.png') scroll repeat-x 0 100% #e7ecee;
	border-bottom-color: $lighter-grey;
}

.hpf-hasZoneTitle {
	.ms-webpart-titleText.ms-webpart-titleText,
	.ms-webpart-titleText.ms-webpart-titleText a {
		font-size: $font-size-3;
		text-indent: -6px;
	}

	/*.hpf-aside .ms-webpartzone-cell .ms-webpart-titleText.ms-webpart-titleText .hpf-zoneTitle .ms-webpart-titleText.ms-webpart-titleText,
	.hpf-zoneTitle .ms-webpart-titleText.ms-webpart-titleText a {
		font-size: $font-size-1;
		text-indent: 0;
	}*/
	.hpf-zoneTitle .ms-webpart-titleText.ms-webpart-titleText,
	.hpf-zoneTitle .ms-webpart-titleText.ms-webpart-titleText a {
		font-size: $font-size-1;
		text-indent: 0;
	}
}

.hpf-zone-hero {
	background: url('#{$brand-images}/rotator-bkd.jpg') scroll repeat-x center 0 transparent;
	border-bottom-color: $darker-white;
	border-top-color: $white;
}

.hpf-page-container .hpf-zone-title {
	background: url('#{$brand-images}/page-title-bkd.jpg') scroll repeat-x 0 0 #444659;
	border-top-color: $white;
}

.hpf-page-container .hpf-zone-title .hpf-page-title-inner h1 {
	color: $white;
	font-size: 34px;
	font-weight: 300;
}

.hpf-publishing .hpf-zone-panel.hpf-aside-ontop {
	background: none;
	border: 0;

	.hpf-grid-span-7 {
		@include box-shadow(0 9px 9px -9px rgba(0, 0, 0, .5) inset);
		// sass-lint:disable-next-line
		background: linear-gradient(to right,
			rgba(232, 230, 228, 1) 0%,
			rgba(239, 237, 234, 1) 11%,
			rgba(239, 237, 234, 1) 89%,
			rgba(232, 230, 228, 1) 100%);
		padding: 50px 30px;
	}

	.ms-webpart-titleText.ms-webpart-titleText,
	.ms-webpart-titleText.ms-webpart-titleText a {
		font-size: $font-size-3;
		font-weight: $font-weight-light;
		line-height: 26px;
	}
}

/*#endregion*/


/*#region $NEWSITEM*/

.hpf-newsItem {
	border-bottom: 1px solid $lighter-grey;
	padding: 40px 0;

	&:first-child {
		padding-top: 0;
	}
}

.hpf-newsItem__image {
	min-height: 1px;

	img {
		max-width: 100%;
	}
}

.hpf-newsItem__title {
	font-size: $font-size-4;
	line-height: 1.5;
}

.hpf-newsItem__metadata {
	line-height: 1;
	margin-bottom: 14px;
	margin-top: 5px;
}

.hpf-newsItem__summary {
	* {
		font-size: $font-size-6;
		line-height: 1.6;
		margin: 0;
	}
}

.hpf-newsItem__date {
	border-right: 1px solid $lighter-grey;
	color: $green;
	font-size: $font-size-9;
	line-height: 1;
	padding-right: 5px;
	text-transform: uppercase;
}

.hpf-newsItem__category {
	color: $dark-blue;
	font-size: $font-size-9;
	line-height: 1;
	padding-left: 15px;
	position: relative;
	text-transform: uppercase;

	&::before {
		background-image: url('#{$brand-images}/mega-sprite.png');
		background-position: -100px -250px;
		background-repeat: no-repeat;
		content: '';
		height: 10px;
		left: 2px;
		position: absolute;
		top: 2px;
		width: 10px;
	}
}

/*#endregion*/

/*#region $LOCATIONS*/

.locations__title {
	color: $dark-blue;
	font-size: $font-size-3;
	margin-bottom: 12px;
}

.locations__dropdown {
	margin-bottom: 10px;
}

.locationsList__item {
	border-bottom: 1px solid $light-grey;
	border-right: 1px solid $light-grey;
	margin: 0 -15px !important;
	padding: 15px !important;

	&.active {
		background-color: $light-blue-grey;
	}

	&:hover {
		background-color: $light-blue-grey;
		cursor: pointer;
	}
}

.locationsList__title {
	color: $orange;
	font-size: $font-size-5;
	margin-bottom: 6px;
}

.mobileLocationsSelector {
	display: none;
	margin-bottom: 30px;
}

#hpf-locations-filter {
	font-size: 16px;
	padding: 8px 0 5px 13px;
	width: 100%;
}

.locationDetails__mapContainer {
	border: 1px solid $light-grey;
	height: 0;
	margin-bottom: 20px;
	padding-bottom: 62.5%;
	width: 99.4%;
}

.locationDetails__infoContainer {
	h2 {
		color: $orange;
		font-size: $font-size-3;
		margin-bottom: 10px;
	}

	table {
		text-align: left;
	}

	th {
		text-align: left;
		vertical-align: top;
		width: 155px;
	}
}

/*#endregion*/

/*#region $HEADER*/

#s4-titlerow {
	background: $bg-white url('#{$brand-images}/titlerow-bkgd.png') repeat-x 0 bottom;
	border-bottom-color: #f3f5f6;
	border-top: 4px solid $dark-blue;
}

/*#region $TOPNAVIGATION*/

/* top nav anchor text */
#DeltaTopNavigation a.static {
	color: $orange;
	font-size: 22px;
	font-weight: $font-weight-normal;
	margin-right: 0;
	padding-bottom: 20px;
}

#DeltaTopNavigation li.selected a.static,
#DeltaTopNavigation li.hover a.static {
	color: $dark-blue;
}

#DeltaTopNavigation .ms-core-listMenu-horizontalBox .dynamic-children.additional-background {
	background-image: none;
	padding-right: 0;
}

.ms-core-navigation {
	font-family: $font-family-sans;
}

/* top nav sub titles */
#DeltaTopNavigation .hpf-navigationSubtitle {
	color: $dark-blue;
	font-weight: $font-weight-normal;
}

/* top nav flyout anchor text */
#DeltaTopNavigation ul.dynamic {
	@include box-shadow(none);
	background: rgba(255, 255, 255, .95);
	border: 0;
	border-bottom: 6px solid $white;
	border-top: 1px solid #c2cdd3;
	margin-top: 30px;
}

#DeltaTopNavigation ul.dynamic::before,
#DeltaTopNavigation ul.dynamic::after {
	border-bottom: 5px solid rgba(0, 0, 0, .3);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	content: ' ';
	display: block;
	height: 0;
	left: 30%;
	position: absolute;
	top: -5px;
	width: 0;
	z-index: 10;
}

#DeltaTopNavigation ul.hpf-navArrowUp-Wide::before,
#DeltaTopNavigation ul.hpf-navArrowUp-Wide::after {
	border-bottom: 5px solid rgba(0, 0, 0, .3);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	content: ' ';
	display: block;
	height: 0;
	left: 15%;
	position: absolute;
	top: -5px;
	width: 0;
	z-index: 10;
}

#DeltaTopNavigation ul.dynamic::after {
	border-bottom: 5px solid $white;
	margin-left: -1px;
	z-index: 11;
}

#DeltaTopNavigation ul.dynamic ul.dynamic::after,
#DeltaTopNavigation ul.dynamic ul.dynamic::before {
	display: none;
}

/* top nav flyout anchor text */
#DeltaTopNavigation li.dynamic {
	border-top: 1px solid $white;
}

#DeltaTopNavigation a.dynamic {
	background: rgba(50, 53, 68, .1);
	border-top: 0;
	color: $green;

	&:hover {
		background: $dark-blue;
		border-left: 4px solid $orange;
		color: $white;
		padding-left: 14px;
	}
}

#DeltaTopNavigation ul.dynamic li:first-child {
	border-top-width: 6px;
}

#DeltaTopNavigation ul.hpf-listContainer {
	background-color: transparent;
	border: 0;
	white-space: nowrap;

	& > li {
		display: inline-block;
		vertical-align: top;
	}

	.hpf-colList {
		background: rgba(255, 255, 255, .95);
		background-color: $white;
		border-bottom: 6px solid $white;
		border-right: 1px solid $white;

		ul {
			display: none !important;
		}
	}
}

// Hide all navigation items beyond second level
#DeltaTopNavigation ul ul.dynamic ul.dynamic {
	display: none !important;
}

/*#endregion*/

/*#region $LEFTNAV*/
.hpf-publishing #sideNavBox .Menu-Vertical {
	@include box-shadow(0 3px 3px -3px rgba(0, 0, 0, .5));
	background: $white;
	border-bottom: 1px solid $white-grey;
	border-right: 1px solid $white-grey;
	color: $green;
}

.hpf-publishing #sideNavBox .Menu-Vertical li a {
	border-left-color: $dark-blue;
	color: $dark-blue;
	font-size: 21px;
	font-weight: 300;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li {
	border-left-color: $whiter-grey;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li.selected {
	background: $bg-white;
	border-left-color: $light-blue;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li a {
	border-top-color: $whiter-grey;
	font-size: 15px;
	font-weight: $font-weight-normal;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li.selected a {
	color: $dark-blue;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li.selected li a {
	color: $green;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li a {
	background-image: url('#{$brand-images}/bullet-sprite.png');
	background-position: 22px 10px;
	background-repeat: no-repeat;
	color: $green;
	font-size: 13px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li.selected a {
	background-position: 22px -490px;
	color: $dark-blue;
	font-weight: $font-weight-bold;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li.selected li a {
	background-image: url('#{$brand-images}/bullet-sprite.png');
	background-position: 34px -2493px;
	background-repeat: no-repeat;
	font-size: 12px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li li.selected a {
	background-position: 34px -2993px;
	color: $dark-blue;
	font-weight: $font-weight-bold;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li li.selected li a {
	background-position: 46px -2493px;
}

.hpf-publishing #sideNavBox .Menu-Vertical li li li li.selected li.selected a {
	background-position: 46px -2993px;
}


/*#endregion*/


/*#region $BREADCRUMB*/

#hpf-breadcrumb {
	background: #e5e4e2;
	border-top-color: #d6d3ce;
	color: $dark-blue;
	font-size: $font-size-9;

	a {
		color: $dark-blue;
	}
}

/*#endregion*/


/*#region $ANKLE*/

.hpf-ankle {
	@include linear-gradient-with-filter(#303341, #303341, $dark-blue, 0%, 80%);
	border-bottom: 1px solid #252833;
	border-top: 1px solid $black;
}

.hpf-ankle h2 {
	color: $light-grey;
}

.hpf-ankle h2 strong {
	color: $white;
}

.hpf-ankle label {
	color: $white;
}

.hpf-ankle input {
	@include border-radius(4px);
	@include box-shadow(inset 0 0 10px #bbb);
	background: $white;
	color: $dark-blue;
	margin-top: 0;
}

.hpf-ankle fieldset {
	margin-left: -15px;
	padding-left: 15px;
}

.hpf-ankle fieldset.hpf-saving {
	background: url('#{$brand-images}/loader-anim.gif') no-repeat 0 5px;
}

.hpf-subscribeMessage {
	color: $light-grey;
	font-size: $font-size-6;
	font-style: italic;
}

.hpf-subscribeError {
	color: $orange;
}

/*#endregion*/


/*#region $FOOTER*/

.hpf-footer {
	@include linear-gradient-with-filter(#404355, #404355, $dark-blue, 0%, 80%);
	border-bottom: 7px solid #191a22;
	border-top: 1px solid #434656;
	clear: both;
	padding-bottom: 60px;

	h2 {
		color: $white;
		font-size: $font-size-2;
		margin-bottom: 22px;
		margin-top: 45px;
	}

	p {
		color: $light-grey;

		a {
			color: $light-blue;

			&:hover {
				color: $white;
				text-decoration: none;
			}
		}
	}

	ul.hpf-footer-followList li {
		float: left;

		&:last-child {
			a {
				margin: 0;
			}
		}
	}

	.hpf-footerSocialIcon {
		background: url('#{$brand-images}/mega-sprite.png') no-repeat -50px 0;
		height: 51px;
		margin-right: 8px;
		width: 51px;

		&:hover {
			background-position: -50px -51px;
		}
	}

	a.hpf-twitter {
		background-position: -109px 0;

		&:hover {
			background-position: -109px -51px;
		}
	}

	a.hpf-linkedin {
		background-position: -168px 0;

		&:hover {
			background-position: -168px -51px;
		}
	}

	a.hpf-youtube {
		background-position: -227px 0;

		&:hover {
			background-position: -227px -51px;
		}
	}

	a.hpf-instagram {
		background-position: -286px 0;

		&:hover {
			background-position: -286px -51px;
		}
	}
}

/*#endregion*/


/*#region $WEBPARTCHROME*/
h2,
h3,
.ms-h2,
.ms-h3,
.ms-headerFont {
	font-weight: $font-weight-light;
}

.hpf-zone-hero .ms-webpartzone-cell {
	margin-bottom: 0;
}


h2.ms-webpart-titleText.ms-webpart-titleText {
	margin-bottom: 17px;
}

.ms-webpart-titleText.ms-webpart-titleText,
.ms-webpart-titleText.ms-webpart-titleText a {
	color: $dark-blue;
	font-family: $font-family-sans;
	font-size: $font-size-1;
	line-height: 1.4;
	text-align: left !important;
	white-space: normal;
}

.ms-webpart-titleText.ms-webpart-titleText a {
	color: $orange;
}

/* ex: careers landing page */
.hpf-publishing-landing-nav-1,
.hpf-publishing-content-nav-aside-1 {

	.ms-webpart-titleText.ms-webpart-titleText {
		margin-bottom: 20px;
	}

	.ms-webpart-titleText.ms-webpart-titleText,
	.ms-webpart-titleText.ms-webpart-titleText a {
		color: $light-blue;
		font-size: $font-size-2;
		font-weight: $font-weight-normal;
	}
}

.ms-webpart-titleText.ms-webpart-titleText img {
	height: 42px;
	width: 42px;
}

.ms-webpart-titleText.ms-webpart-titleText nobr {
	white-space: normal;
	word-wrap: break-word;
}

.ms-webpart-titleText.ms-webpart-titleText nobr em {
	color: $light-blue;
}

.ms-webpart-titleText nobr em.hpf-webPartTileSmallEm {
	display: block;
	font-size: $font-size-3;
	font-weight: $font-weight-normal;
}

.hpf-hasWebPartLogo {
	.ms-wpContentDivSpace {
		padding-left: 44px;
	}

	h2.ms-webpart-titleText {
		overflow: visible;
		padding-left: 44px;
		position: relative;
		text-indent: -8px;

		// forcing the title to fit inside the columns with some letterspacing trickery
		a[href*='/wellness/wellness-and-the-first-nations-health-authority/wellness-streams'] {
			letter-spacing: -.005em;
		}
	}

	h2.ms-webpart-titleText img {
		left: 0;
		position: absolute;
		top: 0;
	}
}


/*#endregion*/


/*#region $BANNERROTATOR */
.hpf-bannerRotator {
	position: relative;

	.hpf-slide {
		overflow: hidden;
	}

	.hpf-mediaItem .hpf-mediaItem-media {
		img {
			max-width: 100%;
		}
	}

	.hpf-mediaItem-body {
		color: $white;
		max-height: 310px;
		padding: 60px 0 80px;

		h2 {
			color: $white;
			font-size: $font-size-1;
			font-weight: $font-weight-normal;
			overflow: hidden;

			/* reset so that HTML inside the 
			<h2> doesn't throw off the layout */
			ul,
			ol,
			p,
			h2,
			h3,
			h4,
			h5,
			h6,
			span {
				background: transparent;
				margin: 0;
				padding: 0;
			}
		}

		.hpf-mediaItem-content {
			max-height: 265px;
			overflow: hidden;
		}
	}

	.hpf-mediaItem-body a.hpf-button {
		margin-top: 1em;
	}
}

.flexslider {
	// Needed for WPhone8 to enable scrolling
	-ms-touch-action: pan-y !important;
	touch-action: pan-y !important;
}

.hpf-flexsliderWrapper {
	position: relative;

	.hpf-controls {
		bottom: 30px;
		height: 22px;
		left: 0;
		margin: 0 22px;
		position: absolute;
		z-index: 5000;

		.flex-control-nav,
		.flex-direction-nav {
			position: static;
		}
	}
}



.flex-control-nav li {
	margin: 0 3px;
}

.flex-direction-nav a {
	background-image: url('#{$brand-images}/mega-sprite.png');
	background-repeat: no-repeat;
	height: 22px;
	margin: 0;
	opacity: .7;
	top: 0;
	width: 22px;
}

.flex-direction-nav .flex-prev {
	background-position: -50px -150px;
	left: -22px;
}

.flex-direction-nav .flex-next {
	background-position: -115px -150px;
	right: -22px;
}

.flexslider:hover .flex-next {
	opacity: .7;
	right: -22px;
}

.flexslider:hover .flex-prev {
	left: -22px;
	opacity: .7;
}

.flex-control-paging li a {
	@include box-shadow(none);
	background: rgba(115, 117, 128, .5);
	margin-top: 6px;
}

.flex-control-paging li a:hover,
.flex-control-paging li a.flex-active {
	background: rgba(115, 117, 128, 1);
	border-color: rgb(115, 117, 128);
}
/*#endregion*/


/*#region $LARGEROLLUP */

.hpf-largeRollup {
	> li {
		font-size: $font-size-4;
		font-weight: $font-weight-light;
		@include item-dividers(40px);
	}

	li.hpf-listPaging::after {
		display: none !important;
	}

	li.hpf-featureNewsItem img {
		@include content-image;
	}

	li.hpf-featureNewsItem p {
		font-size: $font-size-6;
	}

	.cssgradients & li.hpf-featureNewsItem::after {
		display: none;
	}

	.no-cssgradients & li.hpf-featureNewsItem {
		border-bottom: 0;
	}

	h3 {
		font-size: $font-size-4;
	}

	.hpf-eventCalendar {
		background: url('#{$brand-images}/fnha-calendar-icon.png') scroll no-repeat 0 0 transparent;
		color: $dark-blue;

		.hpf-eventMonth {
			font-size: $font-size-11;
			line-height: 9px;
			padding-top: 16px;
			text-transform: uppercase;
		}

		.hpf-eventDate {
			font-size: $font-size-7;
			font-weight: $font-weight-bold;
			line-height: 13px;
			padding-top: 1px;
		}
	}

	.hpf-location {
		color: $dark-blue;
		font-size: $font-size-6;
		padding: 2px 0;
	}

	.hpf-date {
		color: $green;
		font-size: $font-size-8 - 1;
		font-style: normal;
		font-weight: $font-weight-normal;
		margin: 0 0 5px;
	}

	.hpf-largeRollup-image {
		float: left;
		margin-right: 3%;
		width: 16%;

		img {
			float: none;
			width: 100%;
		}
	}

	.hpf-largeRollup-content {
		float: left;
		font-size: $font-size-6;
		width: 81%;

		h3 {
			bottom: 5px;
			position: relative;
		}

		p {
			margin: 0;
		}
	}

	img {
		border: 1px solid $light-grey !important;
		float: right;
	}
}

/*#endregion*/


/*#region $REGULARROLLUP */

.hpf-regularRollup {

	> li {
		@include item-dividers(50px);
		font-size: $font-size-6;
		font-weight: $font-weight-light;
		line-height: 24px;
	}

	> li.hpf-listPaging::after {
		display: none !important;
	}

	img {
		@include content-image;
	}

	h3 {
		color: $orange;
		font-size: $font-size-4;
		line-height: 30px;
	}

	.hpf-seeMore {
		font-size: $font-size-6;
	}
}

/*#endregion*/


/*#region $TWOCOLUMNROLLUP*/

.hpf-twoColumnRollup {
	padding: 15px 0;

	li {
		@include box-sizing(border-box);
		float: left;
		margin-bottom: 15px;
		width: 48%;

		p {
			margin: 0;
		}

		h3 a,
		p span {
			@include text-overflow(ellipsis, 100%, 22px);
		}
	}

	li.hpf-rightColumn {
		margin-left: 4%;
	}
}

/*#endregion*/


/*#region $ASIDERWEBPARTS */
.hpf-aside .ms-webpartzone-cell {
	@include item-dividers(50px);

	.ms-webpart-titleText.ms-webpart-titleText,
	.ms-webpart-titleText.ms-webpart-titleText a,
	h2 {
		@include aside-web-part-h2;
	}
}

.hpf-asideFixedWebpart {

	.ms-webpart-titleText.ms-webpart-titleText,
	.ms-webpart-titleText.ms-webpart-titleText a,
	h2 {
		@include aside-web-part-h2;
	}
}

.cssgradients .hpf-aside .ms-webpart-zone:last-child .ms-webpartzone-cell:last-child::after {
	display: none;
}


.hpf-refiners {
	h2 {
		@include aside-web-part-h2;
		text-transform: uppercase;
	}

	.ms-ref-ellipsis {
		white-space: normal;
	}

	.ms-ref-refiner #Value,
	.ms-ref-unsel-toggle,
	#OtherValue,
	#SubmitValue {
		width: 100%;
	}
}

.hpf-refiners__btnClose {
	background-color: transparent;
	border: 0;
	border-radius: 2px;
	display: none;
	font-size: 25px;
	height: 30px;
	line-height: 30px;
	margin: 0;
	min-width: 0;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	z-index: 100;

	&:hover {
		background: none;
		cursor: pointer;
	}
}

/*#endregion*/


/*#region $ASIDEROLLUP */

.hpf-asideRollup {
	@include item-dividers(50px);

	.ms-webpart-zone & {
		margin-bottom: 0;

		&::after {
			display: none;
		}
	}

	h3,
	.link-item {
		font-size: $font-size-7;
		font-weight: $font-weight-normal;
	}

	a {
		color: $green;
	}

	.hpf-date {
		color: $dark-blue;
		font-size: $font-size-7;
		font-style: italic;
		font-weight: $font-weight-bold;
	}
}

/*#endregion*/



/*#region $LANDINGPAGE DIVIDED ROLLUPS */

.hpf-primaryContentRollup {

	& li {
		@include item-dividers(50px);
		margin: 40px 0;

		h3 {
			color: $dark-blue;
			font-size: $font-size-2;
			margin-bottom: 30px;
		}

		p {
			line-height: 24px;
		}

		.hpf-seeMore {
			font-size: $font-size-6;
		}
	}

	& li li {
		@include kill-dividers;
		margin: 0;
	}

	& li:first-child {
		margin-top: 0;
	}
}

/*#endregion*/


/*#region $ASIDEONTOP */

.hpf-aside-ontop {
	.ms-webpartzone-cell {
		@include item-dividers(50px, #c6c2bb);
		margin: 50px 0;
	}

	.ms-webpartzone-cell:first-child {
		margin-top: 0;
	}
}


/*#endregion*/


/*#region $HOMEPAGE*/



/*#endregion*/


/*#region $FEEDBACKFORM*/

.hpf-feedbackForm {

	label {
		color: $dark-blue;
		font-size: $font-size-6;
		font-weight: $font-weight-light;
	}

	input,
	textarea,
	select {
		border: 1px solid $light-brown;
		border-bottom-color: #c3bfb9;
		color: $light-grey;

		&:focus {
			color: $dark-blue;
		}
	}

	p.hpf-feedbackWarning {
		color: $warning-red;
	}

	p.hpf-feedbackMessage {
		color: $green;
	}
}

#hpf-feedbackSuccess {
	background: none repeat scroll 0 0 $white;
	display: none;
	padding: 120px 70px;

}

#hpf-feedbackSuccess span {
	display: block;
}

/*#endregion*/


/*#region $EVENT AND NEWS DETAILS*/

.hpf-eventsArticle .hpf-article-details,
.hpf-newsArticle .hpf-article-details {
	font-weight: $font-weight-bold;
	margin-bottom: 15px;
}

.hpf-eventsArticle .hpf-article-details .hpf-location {
	display: block;
	margin-top: 8px;
}

/*#endregion*/


/*#region $REFINEMENT PANEL*/

.ms-ref-filterSel > div,
.ms-ref-refiner #Value > a:hover > div,
.ms-ref-refiner #Value > a:active > div {
	color: $green;
}

.ms-ref-refiner {
	h3 {
		color: $dark-blue;
		font-size: $font-size-7;
		font-weight: $font-weight-bold;
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.ms-ref-name {
		background: url('#{$brand-images}/bullet-sprite.png') no-repeat 0 -994px transparent;
		font-size: $font-size-7;
		font-weight: $font-weight-bold;
		padding-left: 13px;
	}

	a:hover {
		> div {
			color: $green;
		}

		.ms-ref-name {
			color: $green;
			text-decoration: underline;
		}
	}

	.ms-ref-filterSel {
		background: url('#{$brand-images}/bullet-sprite.png') no-repeat 0 -994px;

		&:hover > div {
			text-decoration: none;
		}

		.ms-ref-name {
			background-position: 0 -494px;
			color: $dark-blue !important;
			font-weight: $font-weight-bold;
			text-decoration: none;
		}
	}

	.ms-ref-unsel-toggle {
		font-size: $font-size-9;
		margin-top: 3px;

		&:hover div {
			text-decoration: underline;
		}
	}
}

/*#endregion*/


/*#region $SEARCH RESULTS*/

.hpf-srch-result-custom {
	margin-top: 0;
}

.ms-searchCenter-result-main #SearchBox {
	margin: 15px 0;

	.ms-srch-sbLarge {
		z-index: 1;
	}
}

.ms-srch-sbLarge-searchImg,
.ms-srch-sb-searchImg {
	max-width: 122px;
}

.ms-srch-group {
	margin-top: 0;
}

/* result block */
.ms-srch-group-border {
	border-left: 0;
	margin-left: 0;
	padding-left: 0;

	#GroupBorderTop,
	#GroupBorderBottom {
		display: none;
	}

	.ms-srch-group-content {
		background: url('#{$brand-images}/srch-result-block-bkd.png') repeat-y center 0;
	}

	.ms-srch-item {
		background-image: url('#{$brand-images}/srch-result-block-hr.png');
	}

	.ms-srch-group-title {
		background: url('#{$brand-images}/srch-result-block-hr.png') no-repeat center bottom;
		color: $dark-blue;
		font-size: $font-size-2;
		margin-bottom: 0;
		padding-bottom: 20px;
	}
}

.ms-srch-item,
.ms-srch-bestBetItem {
	// TODO: Using images here b/c itemDivider() mixin was causing problems with the search hover callouts.. Investigate how
	// to leverage CSS gradients to create the separators without impacting the flyouts
	background: url('#{$brand-images}/srch-result-hr.png') no-repeat center bottom;
	margin: 0;
	padding: 15px 20px 26px 0;
	width: 720px;

	&:hover {
		background-color: transparent;
	}

	.ms-srch-item-icon {
		display: none;
	}

	.ms-srch-item-title h3 {
		font-size: $font-size-4;
		margin-bottom: 2px;
		white-space: normal;
		word-break: break-word;
	}

	.ms-srch-item-path {
		color: $green;
		display: block;
		font-size: $font-size-8;
		margin-top: 8px;
	}

	.ms-srch-hover-action .ms-calloutLink:link,
	.ms-srch-hover-action .ms-calloutLink:visited {
		color: $orange;
	}

	.ms-srch-hover-content .js-callout-closeButtonImage {
		display: none;
	}
}

/* IE specific */
.ms-core-needIEFilter .ms-srch-item:hover,
.ms-core-needIEFilter .ms-srch-resultHover:hover,
.ms-core-needIEFilter .edisc-SearchResultItemContainer:hover,
.ms-core-needIEFilter #PageLinkPrev,
.ms-core-needIEFilter #PageLinkNext {
	filter: none;
}


/* promoted result (best bet) */

.ms-srch-bestBetItem {
	background: url('#{$brand-images}/srch-result-block-bkd.png') repeat-y center 0;
	padding: 0;

	.ms-srch-bestBetItem-icon {
		display: none;
	}

	.ms-srch-item-title {
		padding-top: 15px;
	}

	&:first-child .ms-srch-item-title {
		background: url('#{$brand-images}/srch-result-block-hr.png') no-repeat center top;
	}

	.ms-srch-item-body {
		background: url('#{$brand-images}/srch-result-block-hr.png') no-repeat center bottom;
		display: block;
		padding: 0 20px 26px 0;
		width: auto;
	}
}

/* pagination */

.ms-srch-result #Paging li {
	margin: 0 2px;
}

#PageLinkPrev,
#PageLinkNext,
.ms-srch-result #PagingSelf a,
.ms-srch-result #PagingLink a {
	@include button;
	color: $white;
	display: inline-block;
}

.ms-srch-result #PagingSelf a,
.ms-srch-result #PagingLink a {
	padding: 2px 8px 4px 9px;
}

.ms-srch-result #PagingSelf a {
	@include button-disabled;

	&:hover {
		color: $white;
	}
}

#PageLinkPrev {
	margin-right: 5px;
}

#PageLinkNext {
	margin-left: 5px;
}

#PageLinkPrev .ms-promlink-button-image,
#PageLinkNext .ms-promlink-button-image {
	margin-left: 10px;
	overflow: visible;

	img {
		display: none;
	}
}

#PageLinkPrev .ms-promlink-button-image {
	@include arrow(5px, $white, left, 50%);
}

#PageLinkNext .ms-promlink-button-image {
	@include arrow(5px, $white, right, 50%);
	margin-left: -19px;
}

/*#endregion*/


/*#region $LARGE SEARCH BAR*/

.ms-srch-sbLarge-fullWidth {
	margin-right: 32px;
	width: 435px;
}

/* There can be multiple #SearchBox's on a page */
.hpf-zone-panel #SearchBox {
	width: 480px;
}

.hpf-zone-panel .ms-srch-sbLarge {
	position: relative;
	width: 100%;
}

.hpf-zone-panel .ms-srch-sb-searchLink {
	height: 29px;
	position: absolute;
	right: 0;
}

/*#endregion*/


/*#region $HELPER CLASSES*/

.overflow--hidden {
	overflow: hidden !important;
}

.hpf-hideOnDesktop {
	display: none;
}

/* bkd colors */
.hpf-bkd {
	background: $dark-blue;
}

.hpf-bkd-alt1 {
	background: $orange;
}

.hpf-bkd-alt2 {
	background: $light-blue;
}

.hpf-bkd-alt3 {
	background: $green;
}

.hpf-bkd-alt4 {
	background: $light-grey;
}

/* text colors */

.hpf-color-alt0 {
	color: $dark-blue;
}

.hpf-color-alt1 {
	color: $orange;
}

.hpf-color-alt2 {
	color: $light-blue;
}

.hpf-color-alt3 {
	color: $green;
}

.hpf-color-alt4 {
	color: $light-grey;
}

.hpf-color-alt5 {
	color: $white;
}

/* text sizes */

.hpf-font-size-1 {
	font-size: $font-size-1;
}

.hpf-font-size-2 {
	font-size: $font-size-2;
}

.hpf-font-size-3 {
	font-size: $font-size-3;
}

.hpf-font-size-4 {
	font-size: $font-size-4;
}

.hpf-font-size-5 {
	font-size: $font-size-5;
}

.hpf-font-size-default {
	font-size: $font-size-6;
}

.hpf-font-size-6 {
	font-size: $font-size-6;
}

.hpf-font-size-7 {
	font-size: $font-size-7;
}

.hpf-font-size-8 {
	font-size: 11px;
}

.hpf-smallCaps {
	font-size: 78.5714%;
	text-transform: uppercase;
}

/*text weights*/

.hpf-fontWeight-bold {
	font-weight: $font-weight-bold;
}

.hpf-font-weight-normal {
	font-weight: $font-weight-normal;
}

.hpf-fontWeight-light {
	font-weight: $font-weight-light;
}

/* lists brand */

.hpf-list li,
.ms-rtestate-field li span.ms-rteStyle-list,
.hpf-arrowList li,
.ms-rtestate-field li span.ms-rteStyle-arrowList,
.hpf-arrowList-large li,
.ms-rtestate-field li span.ms-rteStyle-arrowList-large,
.hpf-arrowListOnDark li,
.hpf-arrowListOnDark-alt2 li,
.hpf-arrowList-alt2 li,
.ms-rtestate-field li span.ms-rteStyle-arrowList-alt2,
.hpf-separatedInlineList-small li,
.ms-rtestate-field ul li:not([class]) {
	background-image: url('#{$brand-images}/bullet-sprite.png');
}

.hpf-list li,
.ms-rtestate-field li span.ms-rteStyle-list,
.ms-rtestate-field ul li:not([class]) {
	background-position: 0 -1490px;
}

.hpf-arrowList li,
.ms-rtestate-field li span.ms-rteStyle-arrowList {
	background-position: 0 9px;
}

.hpf-arrowList-alt2 li,
.ms-rtestate-field li span.ms-rteStyle-arrowList-alt2 {
	background-position: 0 -491px;
}

.hpf-arrowList-large li,
.ms-rtestate-field li span.ms-rteStyle-arrowList-large {
	background-position: 0 13px;
	font-size: $font-size-4;
}

.hpf-arrowListOnDark li {
	background-position: 0 -991px;
}

.hpf-arrowListOnDark-alt2 li {
	background-position: 0 -4491px;
}

.hpf-separatedInlineList-small li {
	background-position: 0 -1998px;
}

.hpf-separatedInlineList-small li:first-child {
	background: transparent;
}

.hpf-arrowListOnDark li,
.hpf-arrowListOnDark li a {
	color: $light-blue;
}

.hpf-arrowListOnDark li a:hover {
	color: $white;
	text-decoration: none;
}

.hpf-arrowListOnDark-alt2 li,
.hpf-arrowListOnDark-alt2 li a {
	color: $white;
}

.hpf-arrowListOnDark li.hpf-borders {
	background-position: 0 -988px;
	border-bottom: 1px solid $black-blue;
	border-top: 1px solid $darker-blue-grey;
	margin: 4px 0;
	padding-bottom: 6px;
	padding-top: 6px;
	position: relative;
}

.hpf-arrowListOnDark li.hpf-borders + li.hpf-borders {
	border-bottom: 1px solid $black-blue;
	margin: -4px 0 4px;
}

.hpf-arrowListOnDark li.hpf-borders::before {
	border-top: 1px solid $black-blue;
	content: ' ';
	display: block;
	height: 1px;
	left: 0;
	position: absolute;
	top: -2px;
	width: 100%;
}

.hpf-arrowListOnDark li.hpf-borders + li.hpf-borders::before {
	display: none;
}

.hpf-arrowListOnDark li.hpf-borders::after {
	border-top: 1px solid $darker-blue-grey;
	bottom: -2px;
	content: ' ';
	display: block;
	height: 0;
	left: 0;
	position: absolute;
	width: 100%;
}

.hpf-arrowListAlt2 li {
	background-position: 0 -491px;
}

/* buttons brand */

.hpf-button,
.hpf-button-alt1,
.hpf-button-small,
.hpf-button-big,
.hpf-button-big-alt1,
.hpf-button-download,
.hpf-button-share,
.ms-rteElement-button,
.ms-rteElement-button-download {
	@include button;
}

.hpf-button {
	&--refiner {
		display: none;
	}
}

.hpf-button-small {
	@include box-shadow(0);
	font-weight: $font-weight-normal;
}

.hpf-button-download,
.ms-rteElement-button-download {
	font-weight: $font-weight-normal;

	&::after {
		background: url('#{$brand-images}/bullet-sprite.png') scroll no-repeat 0 -4000px transparent;
		content: ' ';
		height: 13px;
		left: 6px;
		position: absolute;
		top: 5px;
		width: 9px;
	}
}

.hpf-button-share::after {
	background: url('#{$brand-images}/bullet-sprite.png') scroll no-repeat 0 -5500px transparent;
	content: ' ';
	height: 19px;
	left: 11px;
	position: absolute;
	top: 9px;
	width: 19px;
}

.hpf-button-prev {
	padding-left: 20px;
}

.hpf-button-next {
	padding-right: 20px;
}

.hpf-button-prev::after,
.hpf-button-next::after {
	background: url('#{$brand-images}/bullet-sprite.png') scroll no-repeat 0 -3500px transparent;
	content: ' ';
	height: 8px;
	left: 8px;
	position: absolute;
	top: 10px;
	width: 8px;
}

.hpf-button-next::after {
	background-position: -133px -3500px;
	left: auto;
	right: 8px;
}

.hpf-button-disabled {
	@include button-disabled;
}

.hpf-button-alt1,
.hpf-button-big-alt1 {
	@include linear-gradient(#545a68, #545a68, #454a56);
	border-color: #313443;
	color: $light-grey;


	&::before {
		border-top: 1px solid #7e828d;
	}

	&:link,
	&:visited {
		color: $light-grey;
	}

	&:hover {
		@include linear-gradient(#667e8d, $light-blue, #667e8d);
		color: $white;
	}

	&:hover::before {
		border-top-color: #9db3bf;
	}
}

.hpf-share {
	margin-bottom: 40px;
	margin-top: 30px;
}

.hpf-share__button {
	cursor: pointer;
	padding: 0;

	span {
		@include image-replacement;
		background: url('#{$brand-images}/addthis-sprite.png') scroll no-repeat 0 0;
		display: inline-block;
		height: 33px;
		overflow: hidden;
		width: 36px;
	}
}

.hpf-share__button--facebook span {
	background-position: 10px 2px;
}

.hpf-share__button--twitter span {
	background-position: -57px 2px;
}

.hpf-share__button--linkedin span {
	background-position: -115px 2px;
}

.hpf-share__buttons {
	display: none;
}

/*#endregion*/


/*#region $BROWSER COMPATIBILITY PAGE */

.hpf-publishing-browserCompatibility {
	#hpf-breadcrumb,
	.hpf-ankle,
	.hpf-footer {
		display: none !important;
	}

	#s4-bodyContainer {
		width: 100%;
	}

	.hpf-zone-title {
		background-image: url('#{$brand-images}/rotator-bkd.jpg');
		border-top-width: 1px;
		height: auto;
	}

	.hpf-compatibilityMessage {
		padding: 95px 0 65px;

		p {
			color: #fff;
			font-size: 34px;
			margin-bottom: 20px;

			strong {
				color: $light-blue;
				font-style: italic;
			}
		}
	}
}

/*#endregion*/

/*#region $WELLNESS RESOURCES TOPICS */
li.hpf-wellnessResourcesTopic {
	display: none;
	margin-bottom: 40px;

	h3 {
		color: $dark-blue;
		font-size: $font-size-2;
		margin-bottom: 15px;
	}

	.hpf-wellnessResourcesItem:first-child {
		@include item-dividers-before(30px);
	}
}



li.hpf-wellnessResourcesItem {
	margin-bottom: 40px;
	margin-top: 30px;
	padding-left: 25px;

	a {
		display: block;
	}

	p {
		color: $dark-blue;
		font-size: $font-size-6;
	}

	.hpf-wellnessResourceInfo {
		color: $dark-brown;
		font-size: $font-size-8;
		text-transform: uppercase;
	}

	p,
	.hpf-wellnessResourceInfo {
		line-height: 1.5;
		margin: 2px 0;
	}
}

/*#endregion*/


/*#region $WEB PART SWITCHER */

.hpf-webPartSwitcher {

	.hpf-webPartSwitcherTabs {

		@include carousel-gradient(rgba(255, 255, 255, 0), rgba(125, 154, 170, .15));
		@include item-dividers-before(2px);
		@include item-dividers(2px);
		background-color: transparent;
	}

	ul {
		padding-left: 0;
	}

	li {
		color: $green;
		cursor: pointer;
		float: left;
		font-size: $font-size-5;
		line-height: 30px;
		list-style: none;
		padding: 20px 40px 20px 0;

		&.active {
			color: $dark-blue;
		}
	}
}

/*#endregion*/

.anon-user {
	body {
		overflow-y: scroll;
	}

	#s4-workspace {
		height: auto !important;
		width: auto !important;
	}
}
