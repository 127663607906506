﻿/*@import '../brand-colors';
@import '../global';
@import '../../typography';*/

/*$accordion-background-color: $grey-white;

$accordion-border-radius: $global-border-radius;

$accordion-title-background-color: $white;
$accordion-title-border: 1px solid $accent-primary;
$accordion-title-color: $black;
$accordion-title-font: $font-style-normal normal $font-weight-medium $font-size-medium#{'/'}$line-height-medium $font-family-primary;

$accordion-title-expanded-background-color: $accent-primary;
$accordion-title-expanded-color: $black;

$accordion-icon-color: $accent-primary;
$accordion-icon-expanded-color: $white;*/

@import '../../../../../Vendor/Vendor/go-ui/reference';
@import '../../../../../Vendor/Vendor/go-sharepoint/accordion';

.go-accordion--expanded,
.go-accordion--current {
	background-color: #ffffff;
	color: #292c39;

	h2.go-accordion__heading {
		background-color: #D8D8D8;
		border-color: transparent;
		color: #292c39;

		&::after {
			color: #292c39;
		}
	}
}

.go-accordion h2.go-accordion__heading {
	color: #292c39;

	&::after {
		color: #292c39;
	}
}

