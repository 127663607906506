﻿// reference/patterns/_skeleton-cards.scss

@import '../mixins/images';
@import '../variables/colors';

@mixin skeleton-card-photo {
	@include background-image('skeleton-cards/photo', sp(8), sp(8), no-repeat, center, center);

	background-color: darken($grey-white, 5%);
}


@mixin skeleton-card-news {
	@include background-image('skeleton-cards/news', 100%, auto, no-repeat, 0, 0);
}


@mixin skeleton-card-generic-two-column {
	@include background-image('skeleton-cards/generic-two-column', 100%, auto, no-repeat, 0, 0);
}
