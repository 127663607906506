// reference/mixins/_animation.scss

@import '../variables/animation';
@import '../variables/colors';
@import 'helpers';
@import 'images';

/// Create a keyframe block using a specific name.
///
/// @mixin
/// @section Animation Mixins
/// @param $animation-name The name of the animation.
/// @example
///  @include keyframes(pulse) {
///      to {
///          box-shadow: 0 0 0 15px transparentize($grey-light, 1);
///      }
///  }
@mixin keyframes($animation-name) {
	@keyframes #{$animation-name} {
		@content;
	}
}


/// Put a specified keyframe animation in the element.
///
/// @mixin
/// @section Animation Mixins
/// @param $animation-name The name of the animation.
/// @example
///  @include animation('pulse 1.75s infinite cubic-bezier(.66, 0, 0, .75)');
@mixin animation($animation-name) {
	animation: #{$animation-name};
}


@mixin spinner(
	$color: $animation-spinner-color,
	$duration: 1s,
	$thickness: 10px,
	$size: 50px
) {
	&::after {
		animation: pulsate $duration infinite ease-out;
		border: $thickness solid $color;
		border-radius: 50%;
		content: '';
		display: block;
		height: $size;
		margin: 0 auto;
		opacity: 0;
		width: $size;
	}

	@keyframes pulsate {
		0% {
			opacity: 0;
			transform: scale(.1);
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0;
			transform: scale(1);
		}
	}
}

@mixin rotate-saving-icon(
	$color: $accent-primary,
	$filename: 'icons/spinner',
	$icon-size: sp(2)
) {
	padding-left: calc(#{$icon-size} + #{sp(1)});
	position: relative;

	&::before {
		@keyframes rotate-icon {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(359deg);
			}
		}

		@include content;
		@include background-image-fill($filename, $color, $icon-size, $icon-size);

		animation: rotate-icon 1s infinite linear;
		height: $icon-size;
		left: 0;
		width: $icon-size;
	}
}
