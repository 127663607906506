﻿//jQuery FlexSlider v2.0
//http://www.woothemes.com/flexslider/

//Copyright 2012 WooThemes
//Free to use under the GPLv2 license.
//http://www.gnu.org/licenses/gpl-2.0.html

//Contributing author: Tyler Smith ($mbmufffin)



/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
	outline: none;
}

.slides,
.flex-control-nav,
.flex-direction-nav {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
	margin: 0;
	padding: 0;
}

.flexslider .slides > li {
	backface-visibility: hidden;
	display: none;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
	display: block;
	width: 100%;
}

.flex-pauseplay span {
	text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides::after {
	clear: both;
	content: '.';
	display: block;
	height: 0;
	line-height: 0;
	visibility: hidden;
}

html[xmlns] .slides {
	display: block;
}

* html .slides {
	height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
	display: block;
}


/* FlexSlider Default Theme
*********************************/
.flex-viewport {
	max-height: 2000px;
	transition: all 1s ease;
}

.loading .flex-viewport {
	max-height: 300px;
}

.carousel li {
	margin-right: 5px;
}


/* Direction Nav */
.flex-direction-nav {
	height: 0;
}

.flex-direction-nav a {
	background: url('images/bg_direction_nav.png') no-repeat 0 0;
	cursor: pointer;
	display: block;
	height: 30px;
	margin: -20px 0 0;
	opacity: 0;
	position: absolute;
	text-indent: -9999px;
	top: 50%;
	transition: all .3s ease;
	width: 30px;
	z-index: 10;
}

.flex-direction-nav .flex-next {
	background-position: 100% 0;
	right: -36px;
}

.flex-direction-nav .flex-prev {
	left: -36px;
}

.flexslider:hover .flex-next {
	opacity: .8;
	right: 5px;
}

.flexslider:hover .flex-prev {
	left: 5px;
	opacity: .8;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
	opacity: 1;
}

.flex-direction-nav .flex-disabled {
	cursor: default;
	filter: alpha(opacity=30);
	opacity: .3 !important;
}

/* Control Nav */
//.flex-control-nav {width: 100%; position: absolute; bottom: -40px; text-align: center;}
.flex-control-nav li {
	display: inline-block;
	margin: 0 6px;
}

.flex-control-paging li a {
	background: rgba(0, 0, 0, .5);
	border-radius: 20px;
	box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
	cursor: pointer;
	display: block;
	height: 11px;
	text-indent: -9999px;
	width: 11px;
}

.flex-control-paging li a:hover {
	background: rgba(0, 0, 0, .7);
}

.flex-control-paging li a.flex-active {
	background: rgba(0, 0, 0, .9);
	cursor: default;
}

.flex-control-thumbs {
	margin: 5px 0 0;
	overflow: hidden;
	position: static;
}

.flex-control-thumbs li {
	float: left;
	margin: 0;
	width: 25%;
}

.flex-control-thumbs img {
	cursor: pointer;
	display: block;
	opacity: .7;
	width: 100%;
}

.flex-control-thumbs img:hover {
	opacity: 1;
}

.flex-control-thumbs .flex-active {
	cursor: default;
	opacity: 1;
}

@media screen and (max-width: 860px) {
	.flex-direction-nav .flex-prev {
		left: 0;
		opacity: 1;
	}

	.flex-direction-nav .flex-next {
		opacity: 1;
		right: 0;
	}
}
