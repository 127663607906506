﻿@import '../../../../../brand-reference';

$block: '#{$global-prefix}-snippet';

.#{$block} {
	&-enhanced[data-name='Image'] {
		border: 0;
		display: inline-block;
		margin: sp(2) 0;
		min-height: $photo-thumbnail-height;
		min-width: $photo-thumbnail-width;
		padding: 0;
		position: relative;
		z-index: 1;

		&::before {
			content: '';
		}

		&[data-align='left'] {
			float: left;
			margin: sp(1) sp(2) sp(1) 0;
		}

		&[data-align='right'] {
			float: right;
			margin: sp(1) 0 sp(1) sp(2);
		}

		&[data-loaded='1'] {
			height: auto;
			width: auto;
		}

		&:not([data-populated]),
		body:not(.#{$global-prefix}-editMode) &:not([data-loaded]) {
			@include skeleton-card-photo;
			border-radius: $photo-border-radius;
		}

		body:not(.#{$global-prefix}-editMode) & {
			&:not([data-loaded]) {
				@include spinner($grey-dark, 1s, 5px, sp(3));

				&::after {
					left: 50%;
					margin: sp(-2) 0 0 sp(-2);
					position: absolute;
					top: 50%;
				}

				> * {
					display: none;
				}
			}
		}
	}
}
